import React from 'react'
import { Box } from '@material-ui/core'

const InfoQueryLanguage = () => (
  <>
    <div>
      <code>{`mangaid = '1-109493'`}</code>
      <p>
        Select galaxy for given <code>mangaid</code>.
      </p>
    </div>

    <div>
      <code>{`nsa_elpetro_mass > 1e11 and z < 0.04`}</code>
      <p>
        Select nearby galaxies (redshift less then 0.04) with stellar masses
        greater then 10<sup>11</sup> M<sub>sun</sub>.
      </p>
    </div>

    <div>
      <code>{`z<0.03 and (nsa_elpetro_mass > 1e11 and z > 0.02)`}</code>
      <p>
        <Box component="span" color="error.main" fontWeight="fontWeightMedium">
          Note!
        </Box>{' '}
        Multiple logical pair expressions using the <code>or</code>,
        <code>and</code> operators must be enclosed in parentheses. Otherwise,
        the request will be parsed incorrectly. We are working to improve the
        user experience here.
      </p>
    </div>

    <div>
      <code>{`nsa_elpetro_th50_r > 10 or (nsa_elpetro_th50_r < 3 and nsa_elpetro_mass> 1e10)`}</code>
      <p>
        More complicated example using logical operators <code>or</code>,{' '}
        <code>and</code>.
      </p>
    </div>

    <div>
      <code>{`mangaid ~ '1-22'`}</code> or <code>{`z ~ .067`}</code>
      <p>
        Non-strict match operator. Can be applied for string column like{' '}
        <code>mangaid</code> and
        <code>plateifu</code> number columns as well.
      </p>
    </div>
    <div>
      <code>{`mangaid IN ('1-109493', '1-109152')`}</code>
      <p>
        <code>IN</code> statement. Not working for only one argument in the
        sequence.
      </p>
    </div>
  </>
)

export default InfoQueryLanguage
