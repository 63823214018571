import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  imageHidden: {
    display: 'none',
  },
  imageVisible: {
    display: 'block',
  },
}))

export const Image = ({ src, alt, size = 230 }) => {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()
  return (
    <>
      <img
        src={src}
        alt={alt}
        className={loaded ? classes.imageVisible : classes.imageHidden}
        style={{
          width: size,
          // height: size,
          // position: 'relative',
          // display: 'inline',
        }}
        onLoad={(event) => {
          setLoaded(true)
        }}
      />
      {loaded ? null : (
        <Skeleton variant="rect" width={size} height={size} animation="wave" />
      )}
    </>
  )
}

export const LegacySurvey = ({ ra, dec, size = 230, pixscale = 0.2 }) => (
  <a
    href={`http://legacysurvey.org/viewer?ra=${ra}&dec=${dec}&zoom=15`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      src={`https://api.manga.voxastro.org/legacysurveyViewer/cutout.jpg?ra=${ra}&dec=${dec}&pixscale=${pixscale}&width=${size}&height=${size}`}
      alt=""
      size={size}
    />
  </a>
)

export const LegacySurveyVLASS = ({ ra, dec, size = 230, pixscale = 0.2 }) => (
  <a
    href={`https://www.legacysurvey.org/viewer?ra=${ra}&dec=${dec}&layer=vlass1.2&zoom=16`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      src={`https://www.legacysurvey.org/viewer/cutout.jpg?ra=${ra}&dec=${dec}&layer=vlass1.2&pixscale=${pixscale}`}
      alt=""
      size={size}
    />
  </a>
)

export const SDSS = ({
  ra,
  dec,
  size = 230,
  imgsize = 230,
  pixscale = 0.2,
  opt = 'G',
}) => {
  const url_ss = 'https://skyserver.sdss.org/dr16/en/tools/explore/summary.aspx'
  const url_im =
    'https://skyserver.sdss.org/dr16/SkyServerWS/ImgCutout/getjpeg?TaskName=Skyserver.Explore.Image'
  return (
    <a
      href={`${url_ss}?ra=${ra}&dec=${dec}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image
        src={`${url_im}&ra=${ra}&dec=${dec}&scale=${pixscale}&width=${size}&height=${size}&opt=${opt}`}
        alt=""
        size={imgsize}
      />
    </a>
  )
}
