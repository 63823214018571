export const roundValue = (value, precision = 3) => {
  // precision - number of significant digits
  const avalue = Math.abs(value)
  const mantissa = Math.floor(Math.log10(avalue) - (precision - 1))
  const cf = mantissa < 0 ? 10 ** -mantissa : 1 / 10 ** mantissa

  return (Math.sign(value) * Math.round(avalue * cf)) / cf
}

export const round10Value = (value, precision = 3) => {
  // return 10**x at given significance level
  const avalue = Math.abs(value)
  const mantissa = Math.floor(Math.log10(avalue) - (precision - 1))
  const cf = mantissa < 0 ? 10 ** mantissa : 1 / 10 ** -mantissa
  return cf
}
