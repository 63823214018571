import React, { Fragment, useState, useEffect } from 'react'
import {
  TextField,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core/'
import { Autocomplete, Alert } from '@material-ui/lab'
import ButtonLink from '../ButtonLink.js'
import axios from 'axios'

let URL_API = process.env.REACT_APP_URL_API

const decompose = (input, query) => {
  const i0 = input.search(query)
  const i1 = i0 + query.length

  if (i0 !== -1 && query.length > 0) {
    return [input.slice(0, i0), input.slice(i0, i1), input.slice(i1)]
  } else {
    return null
  }
}

const MatchedText = ({ text, query }) => {
  const dec = decompose(text, query)

  if (!dec) {
    return <Fragment>{text}</Fragment>
  } else {
    return (
      <Fragment>
        {dec[0]}
        <b>{dec[1]}</b>
        {dec[2]}
      </Fragment>
    )
  }
}

const ThumbImg = ({ plateifu, width = '38px' }) => {
  const [plate, ifudsgn] = plateifu.split('-')
  return (
    <img
      src={`https://data.sdss.org/sas/dr17/manga/spectro/redux/v3_1_1/${plate}/images/${ifudsgn}_thumb.png`}
      style={{ width: `${width}` }}
      alt=""
    />
  )
}

const SearchID = (props) => {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [nooption, setNooption] = useState('No available targets')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchID() {
      try {
        setLoading(true)
        const res = await axios.get(
          `${URL_API}/targets/identifiers/?id=${inputValue}&size=50`
        )
        setOptions(res.data)
        setLoading(false)
      } catch (error) {
        setNooption(<Alert severity="error">{error.message}</Alert>)
        setLoading(false)
      }
    }

    console.log(inputValue, inputValue.length)
    fetchID()
  }, [inputValue])

  return (
    <Grid container alignItems="center" spacing={2} {...props}>
      <Grid item>
        <Autocomplete
          clearOnEscape
          inputValue={inputValue}
          options={options}
          noOptionsText={nooption}
          filterOptions={(x) => x}
          getOptionSelected={(option, value) =>
            option.plateifu === value.plateifu
          }
          getOptionLabel={(option) => `${option.plateifu}`}
          onChange={(event, value) => {
            setValue(value)
            value && setInputValue(value.plateifu)
          }}
          onInputChange={(event, inputValue, reason) => {
            setValue(null)
            setInputValue(inputValue)
          }}
          style={{ width: 400 }}
          renderOption={(option, { inputValue }) => {
            return (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <ThumbImg plateifu={option.plateifu} />
                </Grid>
                <Grid item>
                  <MatchedText text={option.plateifu} query={inputValue} />
                  <Typography variant="body2">
                    <MatchedText text={option.mangaid} query={inputValue} />
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Plate-IFU or MaNGA ID"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: value && <ThumbImg plateifu={value.plateifu} />,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <ButtonLink
          to={`/visualiser/${value ? value.plateifu : ''}`}
          variant="contained"
          color="primary"
        >
          {' '}
          Go Visualise
        </ButtonLink>
      </Grid>
    </Grid>
  )
}

export default SearchID
