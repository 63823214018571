// import * as React from "react";
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { tableHideAlert, tableFetchRows } from '../../redux/actions.js'
import {
  MenuItem,
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from '@material-ui/core'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Select } from 'formik-material-ui'
import axios from 'axios'
import * as queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  title: {
    width: '170px',
  },
  query: {
    marginLeft: '1rem',
  },
}))

const validateConeSize = (value) => {
  let error
  if (value <= 0) {
    error = 'Not in this Universe!'
  }
  return error
}

const validateTargetName = (value) => {
  let error
  if (value.length < 2) {
    error = 'Too short name!'
  }
  return error
}

function ConeSearch(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [sesameResult, setSesameResult] = useState()

  const qs = queryString.parse(props.location.search)
  const targetName = 'targetName' in qs ? qs.targetName : ''
  const coneSize = 'coneSize' in qs ? qs.coneSize : 1
  const coneUnit = 'coneUnit' in qs ? qs.coneUnit : 'arcmin'

  const handleChange = (values) => {
    props.history.push({
      ...props.location,
      search: queryString.stringify({
        targetName: values.targetName,
        coneSize: values.coneSize,
        coneUnit: values.coneUnit,
      }),
    })
  }

  const badRequest = <Typography color="error">Can't resolve name!</Typography>

  return (
    <Formik
      initialValues={{
        targetName: targetName,
        coneSize: coneSize,
        coneUnit: coneUnit,
      }}
      validate={handleChange}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(tableHideAlert())
        setSesameResult('Name resolving...')
        axios
          .get(
            'https://cdsweb.u-strasbg.fr/cgi-bin/nph-sesame/-ox/SNVA?' +
              values.targetName
          )
          .then((response) => {
            const xml2js = require('xml2js')
            xml2js.parseString(response.data, function (err, result) {
              if (err) {
                console.log('Error in parsing Sesame query request!')
                console.log(err)
              } else {
                if (result.Sesame.hasOwnProperty('Target')) {
                  if (result.Sesame.Target[0].hasOwnProperty('Resolver')) {
                    var resolver = result.Sesame.Target[0].Resolver[0]
                    if (resolver.hasOwnProperty('jpos')) {
                      let output = (
                        <div>
                          Resolved as{' '}
                          {resolver.hasOwnProperty('oname') && (
                            <strong>{resolver.oname}</strong>
                          )}{' '}
                          {resolver.jpos} (RA, DEC: {resolver.jradeg},{' '}
                          {resolver.jdedeg})
                        </div>
                      )
                      setSesameResult(output)

                      // setup query
                      let coneSize
                      if (values.coneUnit === 'arcsec') {
                        coneSize = values.coneSize / 3600.0
                      } else if (values.coneUnit === 'arcmin') {
                        coneSize = values.coneSize / 60.0
                      } else if (values.coneUnit === 'degree') {
                        coneSize = values.coneSize
                      }
                      dispatch(
                        tableFetchRows(
                          `cone(${resolver.jradeg[0]},${resolver.jdedeg[0]},${coneSize})`
                        )
                      )
                    } else setSesameResult(badRequest)
                  } else setSesameResult(badRequest)
                } else setSesameResult(badRequest)
                setSubmitting(false)
              }
            })
          })
      }}
    >
      {({ values, submitForm, handleChange, isSubmitting }) => (
        <Form>
          <Grid container alignItems="flex-end" justify="flex-start">
            <Grid item className={classes.title}>
              <Typography variant="h5">
                Cone Search
                <Link to="/docs">
                  <HelpOutlineIcon fontSize="small" className="ml-1" />
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.query}>
              <Field
                component={TextField}
                name="targetName"
                type="text"
                label="Enter name or coordinates"
                inputProps={{ min: '1' }}
                validate={validateTargetName}
                style={{ width: '350px' }}
                onChange={handleChange}
              />
              <Field
                component={TextField}
                name="coneSize"
                type="number"
                label="Size"
                inputProps={{ min: '1' }}
                validate={validateConeSize}
                className="mx-2"
                style={{ width: '50px' }}
              />
              <FormControl>
                <InputLabel htmlFor="coneUnit">Unit</InputLabel>
                <Field component={Select} name="coneUnit" label="Unit">
                  <MenuItem value={'arcsec'}>arcsec</MenuItem>
                  <MenuItem value={'arcmin'}>arcmin</MenuItem>
                  <MenuItem value={'degree'}>degree</MenuItem>
                </Field>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className="ml-3 mt-3"
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <br />
          {sesameResult}
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}

export default ConeSearch
