import React, { useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { targetFetchDapBPTS, targetSetPointer } from '../../../redux/actions.js'
import { OutputSkeleton } from '../../Loaders'
import { ErrorAlert } from '../MapsBasicContainer'
import Plot from '../../custom-plotly.js'
import {
  Popover,
  Grow,
  Box,
  Typography,
  Slider,
  // Grid,
  // FormGroup,
  FormControlLabel,
  // Input,
  Checkbox,
  Divider,
} from '@material-ui/core'

import Draggable from 'react-draggable'
// import d3 from 'd3'
// import { roundValue, round10Value } from '../../utils.js'

const DraggableWrapper = forwardRef(({ children, ...other }, ref) => {
  return (
    <Draggable ref={ref} handle=".handle">
      {React.cloneElement(children, { ...other })}
    </Draggable>
  )
})

const DraggableGrow = forwardRef(({ children, ...other }, ref) => {
  return (
    <Grow {...other} timeout={0}>
      <DraggableWrapper ref={ref}>{children}</DraggableWrapper>
    </Grow>
  )
})

const BPT = ({ plateifu, type }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [dexErrorX, setDexErrorX] = useState(0.15)
  const [dexErrorY, setDexErrorY] = useState(0.15)
  const [isErrorTied, setIsErrorTied] = useState(true)

  const bpts = useSelector((state) => state.target.DAPBPTS)
  // const bptsStatus = useSelector((state) => state.target.DAPBPTSStatus)
  // const bptsError = useSelector((state) => state.target.DAPBPTSError)
  // const pointXY = useSelector((state) => state.target.pointerXY)

  if (bpts == null) {
    dispatch(targetFetchDapBPTS(plateifu))
    return <OutputSkeleton />
  }

  if (bpts.status === 'error') {
    return <ErrorAlert />
  }

  const handleSettingsClick = (event) => {
    setAnchorEl(event)
  }

  const handleSettingsClose = (event) => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  console.log('+++++ DEBUG BPT ++++++++', bpts)

  const xx_n2_kau03 = [
    -1.3,
    -0.98917518,
    -0.86042732,
    -0.76163561,
    -0.67835035,
    -0.60497456,
    -0.53863778,
    -0.47763481,
    -0.42085464,
    -0.36752553,
    -0.3170856,
    -0.26911068,
    -0.22327122,
    -0.17930516,
    -0.137,
  ]

  const xx_n2_ke01 = [
    -2,
    -1.39278246,
    -1.14126472,
    -0.94826837,
    -0.78556492,
    -0.6422203,
    -0.51262686,
    -0.39345339,
    -0.28252943,
    -0.17834738,
    -0.07980953,
    0.01391275,
    0.10346327,
    0.18935398,
    0.272,
  ]

  const xx_s2_ke01 = [
    -1.2,
    -0.855233,
    -0.71242583,
    -0.60284604,
    -0.510466,
    -0.42907755,
    -0.35549676,
    -0.28783225,
    -0.22485166,
    -0.16569899,
    -0.10975101,
    -0.05653721,
    -0.00569207,
    0.0430751,
    0.09,
  ]

  const xx_s2_ke01_Sy = [-0.345, 0.392]

  const kau03_n2 = {
    x: xx_n2_kau03,
    y: xx_n2_kau03.map((x) => 0.61 / (x - 0.05) + 1.3),
    mode: 'lines',
    type: 'scattergl',
    line: { color: 'blue', dash: 'dash', width: 1 },
    hoverinfo: 'none',
  }

  const ke01_n2 = {
    x: xx_n2_ke01,
    y: xx_n2_ke01.map((x) => 0.61 / (x - 0.47) + 1.19),
    mode: 'lines',
    type: 'scattergl',
    line: { color: 'red', width: 1 },
    hoverinfo: 'none',
  }

  const ke01_s2 = {
    x: xx_s2_ke01,
    y: xx_s2_ke01.map((x) => 0.72 / (x - 0.32) + 1.19),
    mode: 'lines',
    type: 'scattergl',
    line: { color: 'red', width: 1 },
    hoverinfo: 'none',
  }
  const ke01_s2_sy = {
    x: xx_s2_ke01_Sy,
    y: xx_s2_ke01_Sy.map((x) => 1.89 * x + 0.76),
    mode: 'lines',
    type: 'scattergl',
    line: { color: 'red', width: 1 },
    hoverinfo: 'none',
  }
  let obs, traces, xaxis

  if (type === 'NII') {
    obs = {
      x: bpts.data.logN2Ha.filter(
        (e, i) =>
          bpts.data.e_logN2Ha[i] < dexErrorX &&
          bpts.data.e_logO3Hb[i] < dexErrorY
      ),
      y: bpts.data.logO3Hb.filter(
        (e, i) =>
          bpts.data.e_logN2Ha[i] < dexErrorX &&
          bpts.data.e_logO3Hb[i] < dexErrorY
      ),
      error_x: {
        type: 'data',
        array: bpts.data.e_logN2Ha.filter(
          (e, i) =>
            bpts.data.e_logN2Ha[i] < dexErrorX &&
            bpts.data.e_logO3Hb[i] < dexErrorY
        ),
        thickness: 0.5,
        opacity: 0.7,
        color: 'rgba(116, 56, 163, 0.5)',
      },
      error_y: {
        type: 'data',
        array: bpts.data.e_logO3Hb.filter(
          (e, i) =>
            bpts.data.e_logN2Ha[i] < dexErrorX &&
            bpts.data.e_logO3Hb[i] < dexErrorY
        ),
        thickness: 0.5,
        opacity: 0.7,
        color: 'rgba(116, 56, 163, 0.5)',
      },
      name: '',
      type: 'scattergl',
      mode: 'markers',
      marker: {
        symbol: 'star-diamond',
        // color: 'purple',
        color: 'rgba(116, 56, 163, 0.5)',
        size: 5,
        line: {
          color: 'rgba(10, 10, 10, 1)',
          width: 0.1,
        },
      },
      hoverinfo: 'none',
      // hoverinfo: 'x+y',
      // hovertemplate: '%{x:.2f} %{y:.2f}',
    }
    traces = [kau03_n2, ke01_n2, obs]
    // traces = [kau03_n2, ke01_n2]
    xaxis = {
      title: 'log [NII]/H&#945;',
      range: [-1.7, 0.7],
      zeroline: false,
    }
  } else {
    obs = {
      x: bpts.data.logS2Ha.filter(
        (e, i) =>
          (bpts.data.e_logS2Ha[i] < dexErrorX) &
          (bpts.data.e_logO3Hb[i] < dexErrorY)
      ),
      y: bpts.data.logO3Hb.filter(
        (e, i) =>
          (bpts.data.e_logS2Ha[i] < dexErrorX) &
          (bpts.data.e_logO3Hb[i] < dexErrorY)
      ),
      error_x: {
        type: 'data',
        array: bpts.data.e_logS2Ha.filter(
          (e, i) =>
            (bpts.data.e_logS2Ha[i] < dexErrorX) &
            (bpts.data.e_logO3Hb[i] < dexErrorY)
        ),
        thickness: 0.5,
        opacity: 0.7,
        color: 'rgba(116, 56, 163, 0.5)',
      },
      error_y: {
        type: 'data',
        array: bpts.data.e_logO3Hb.filter(
          (e, i) =>
            (bpts.data.e_logS2Ha[i] < dexErrorX) &
            (bpts.data.e_logO3Hb[i] < dexErrorY)
        ),
        thickness: 0.5,
        opacity: 0.7,
        color: 'rgba(116, 56, 163, 0.5)',
      },
      type: 'scattergl',
      mode: 'markers',
      marker: {
        symbol: 'star-diamond',
        // color: 'purple',
        color: 'rgba(116, 56, 163, 0.5)',
        size: 5,
        line: {
          color: 'rgba(10, 10, 10, 1)',
          width: 0.1,
        },
      },
      // hoverinfo: 'x+y',
      hoverinfo: 'none',
    }
    traces = [ke01_s2, ke01_s2_sy, obs]
    xaxis = {
      title: 'log [SII]/H&#945;',
      range: [-1.2, 0.7],
      zeroline: false,
    }
  }

  const layout = {
    title: `BPT-${type}`,
    font: { size: 11 },
    showlegend: false,
    // legend: { orientation: 'h', x: 0, y: 1.05 },
    hovermode: 'noneclosest',
    height: 350,
    width: 300,
    autosize: false,
    margin: { l: 40, r: 0, b: 30, t: 50, pad: 0 },
    xaxis: xaxis,
    yaxis: {
      title: 'log [OIII]/H&#946;',
      range: [-1.5, 1.5],
      zeroline: false,
    },
  }
  // Add new button into thee Plotly Menu Bar
  const newButton = {
    name: 'Plot settings',
    icon: {
      width: 24,
      height: 24,
      viewBox: [0, 0, 24, 24],
      path:
        'M19.1401 12.9404C19.1801 12.6404 19.2001 12.3304 19.2001 12.0004C19.2001 11.6804 19.1801 11.3604 19.1301 11.0604L21.1601 9.48039C21.3401 9.34039 21.3901 9.07039 21.2801 8.87039L19.3601 5.55039C19.2401 5.33039 18.9901 5.26039 18.7701 5.33039L16.3801 6.29039C15.8801 5.91039 15.3501 5.59039 14.7601 5.35039L14.4001 2.81039C14.3601 2.57039 14.1601 2.40039 13.9201 2.40039H10.0801C9.84011 2.40039 9.65011 2.57039 9.61011 2.81039L9.25011 5.35039C8.66011 5.59039 8.12011 5.92039 7.63011 6.29039L5.24011 5.33039C5.02011 5.25039 4.77011 5.33039 4.65011 5.55039L2.74011 8.87039C2.62011 9.08039 2.66011 9.34039 2.86011 9.48039L4.89011 11.0604C4.84011 11.3604 4.80011 11.6904 4.80011 12.0004C4.80011 12.3104 4.82011 12.6404 4.87011 12.9404L2.84011 14.5204C2.66011 14.6604 2.61011 14.9304 2.72011 15.1304L4.64011 18.4504C4.76011 18.6704 5.01011 18.7404 5.23011 18.6704L7.62011 17.7104C8.12011 18.0904 8.65011 18.4104 9.24011 18.6504L9.60011 21.1904C9.65011 21.4304 9.84011 21.6004 10.0801 21.6004H13.9201C14.1601 21.6004 14.3601 21.4304 14.3901 21.1904L14.7501 18.6504C15.3401 18.4104 15.8801 18.0904 16.3701 17.7104L18.7601 18.6704C18.9801 18.7504 19.2301 18.6704 19.3501 18.4504L21.2701 15.1304C21.3901 14.9104 21.3401 14.6604 21.1501 14.5204L19.1401 12.9404ZM12.0001 15.6004C10.0201 15.6004 8.40011 13.9804 8.40011 12.0004C8.40011 10.0204 10.0201 8.40039 12.0001 8.40039C13.9801 8.40039 15.6001 10.0204 15.6001 12.0004C15.6001 13.9804 13.9801 15.6004 12.0001 15.6004Z',
    },
    direction: 'up',
    click: handleSettingsClick,
  }
  const buttonsToRemove = [
    // 'zoom2d',
    // 'pan2d',
    'select2d',
    'lasso2d',
    // 'zoomIn2d',
    // 'zoomOut2d',
    'autoScale2d',
    // 'resetScale2d',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'resetViews',
    // 'toImage',
    'sendDataToCloud',
    'toggleSpikelines',
    'resetViewMapbox',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
  ]
  const config = {
    displaylogo: false,
    responsive: false,
    modeBarButtonsToAdd: [newButton],
    modeBarButtonsToRemove: buttonsToRemove,
  }
  return (
    <>
      <Plot
        data={traces}
        layout={layout}
        config={config}
        // onClick={handlerPlotClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // placement={'right-end'}
        onClose={handleSettingsClose}
        // transition
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionComponent={DraggableGrow}
      >
        <Box m={2} width={300}>
          <Typography
            variant="h6"
            gutterBottom
            className={'handle'}
            style={{ cursor: 'move' }}
          >
            Diagram Settings
          </Typography>
          <Divider />
          <Box my={1}>
            <Typography gutterBottom>
              Maximum X error (dex): {dexErrorX}
            </Typography>
            <Slider
              value={dexErrorX}
              min={0}
              max={0.7}
              step={0.005}
              onChange={(event, value) => {
                if (isErrorTied) {
                  setDexErrorY(value)
                  setDexErrorX(value)
                } else {
                  setDexErrorX(value)
                }
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box my={1}>
            <Typography gutterBottom>
              Maximum Y error (dex): {dexErrorY}
            </Typography>
            <Slider
              value={dexErrorY}
              min={0}
              max={0.7}
              step={0.005}
              onChange={(event, value) => {
                if (isErrorTied) {
                  setDexErrorY(value)
                  setDexErrorX(value)
                } else {
                  setDexErrorY(value)
                }
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isErrorTied}
                onChange={() => setIsErrorTied(!isErrorTied)}
                name="isErrorTied"
                color="primary"
              />
            }
            label="Tied error limits?"
          />
        </Box>
      </Popover>
    </>
  )
}

const BPT2d = ({ plateifu, type }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const bpts = useSelector((state) => state.target.DAPBPTS)
  // const bptsStatus = useSelector((state) => state.target.DAPBPTSStatus)
  // const bptsError = useSelector((state) => state.target.DAPBPTSError)
  const pointXY = useSelector((state) => state.target.pointerXY)

  if (bpts == null) {
    dispatch(targetFetchDapBPTS(plateifu))
    return <OutputSkeleton />
  }

  if (bpts.status === 'error') {
    return <ErrorAlert />
  }

  const handleSettingsClick = (event) => {
    setAnchorEl(event)
  }

  const handleSettingsClose = (event) => {
    setAnchorEl(null)
  }

  const handlerPlotClick = (event) => {
    console.log(
      `Clicked point: x: ${event.points[0].x}, y: ${event.points[0].y}`
    )
    dispatch(targetSetPointer({ x: event.points[0].x, y: event.points[0].y }))
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  // const ionizType = ['SF', 'Comp.', 'AGN', 'Sy', 'LI(N)ER']
  const dataMap = {
    z: type === 'NII' ? bpts.data.msk_BPT_NII : bpts.data.msk_BPT_SII,
    x: bpts.data.meta_maps.xcoord,
    y: bpts.data.meta_maps.ycoord,

    autocontour: false,

    type: 'heatmap',
    colorscale: [
      // [0, 'rgb(166,206,227)'],
      // [0.2, 'rgb(31,120,180)'],
      // [0.4, 'rgb(178,223,138)'],
      // [0.6, 'rgb(51,160,44)'],
      // [0.8, 'rgb(251,154,153)'],
      // [1.0, 'rgb(227,26,28)'],
      [0, 'blue'],
      [0.5, 'green'],
      [1.0, 'red'],
    ],
    colorbar: {
      x: 0.95,
      thickness: 10,
      len: 1.1,
      lenmode: 'fraction',
      tickvals: type === 'NII' ? [0, 0.2, 0.4] : [0.6, 0.8, 1],
      ticktext:
        type === 'NII' ? ['SF', 'Comp.', 'AGN'] : ['SF', 'Sy', 'LI(N)ER'],
    },
    name: '',
    // hoverinfo: 'skip',
    // hovertemplate: '%{ionizType[z]:} %{x:.2f} %{y:.2f}',
  }
  // if pointer is specified plot it, otherwise show only map
  const data = pointXY
    ? [
        dataMap,
        {
          x: [pointXY.x],
          y: [pointXY.y],
          type: 'scattergl',
          mode: 'markers',
          marker: {
            symbol: 'cross',
            color: 'rgba(0, 0, 10, 0.5)',
            size: 8,
            line: {
              color: 'rgb(200, 200, 200)',
              width: 1.0,
            },
          },
          hoverinfo: 'skip',
          showlegend: false,
        },
      ]
    : [dataMap]

  const layout = {
    title: `BPT-${type}`,
    font: { size: 11 },
    xaxis: {
      title: '&#916; X, arcsec',
      scaleanchor: 'y',
      scaleratio: 1,
      range: bpts.data.meta_maps.xrange,
    },
    yaxis: {
      title: '&#916; Y, arcsec',
      range: bpts.data.meta_maps.yrange,
    },
    autosize: false,
    height: 300,
    width: 300,
    margin: { l: 30, r: 0, b: 30, t: 30, pad: 0 },
  }

  const config = {
    displaylogo: false,
    modeBarButtonsToRemove: [
      // 'zoom2d',
      // 'pan2d',
      'select2d',
      'lasso2d',
      // 'zoomIn2d',
      // 'zoomOut2d',
      'autoScale2d',
      // 'resetScale2d',
      'hoverClosestGl2d',
      'hoverClosestPie',
      'toggleHover',
      'resetViews',
      // 'toImage',
      'sendDataToCloud',
      'toggleSpikelines',
      'resetViewMapbox',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
    ],
  }

  // Add new button into thee Plotly Menu Bar
  const newButton = {
    name: 'Map settings',
    icon: {
      width: 24,
      height: 24,
      viewBox: [0, 0, 24, 24],
      path:
        'M19.1401 12.9404C19.1801 12.6404 19.2001 12.3304 19.2001 12.0004C19.2001 11.6804 19.1801 11.3604 19.1301 11.0604L21.1601 9.48039C21.3401 9.34039 21.3901 9.07039 21.2801 8.87039L19.3601 5.55039C19.2401 5.33039 18.9901 5.26039 18.7701 5.33039L16.3801 6.29039C15.8801 5.91039 15.3501 5.59039 14.7601 5.35039L14.4001 2.81039C14.3601 2.57039 14.1601 2.40039 13.9201 2.40039H10.0801C9.84011 2.40039 9.65011 2.57039 9.61011 2.81039L9.25011 5.35039C8.66011 5.59039 8.12011 5.92039 7.63011 6.29039L5.24011 5.33039C5.02011 5.25039 4.77011 5.33039 4.65011 5.55039L2.74011 8.87039C2.62011 9.08039 2.66011 9.34039 2.86011 9.48039L4.89011 11.0604C4.84011 11.3604 4.80011 11.6904 4.80011 12.0004C4.80011 12.3104 4.82011 12.6404 4.87011 12.9404L2.84011 14.5204C2.66011 14.6604 2.61011 14.9304 2.72011 15.1304L4.64011 18.4504C4.76011 18.6704 5.01011 18.7404 5.23011 18.6704L7.62011 17.7104C8.12011 18.0904 8.65011 18.4104 9.24011 18.6504L9.60011 21.1904C9.65011 21.4304 9.84011 21.6004 10.0801 21.6004H13.9201C14.1601 21.6004 14.3601 21.4304 14.3901 21.1904L14.7501 18.6504C15.3401 18.4104 15.8801 18.0904 16.3701 17.7104L18.7601 18.6704C18.9801 18.7504 19.2301 18.6704 19.3501 18.4504L21.2701 15.1304C21.3901 14.9104 21.3401 14.6604 21.1501 14.5204L19.1401 12.9404ZM12.0001 15.6004C10.0201 15.6004 8.40011 13.9804 8.40011 12.0004C8.40011 10.0204 10.0201 8.40039 12.0001 8.40039C13.9801 8.40039 15.6001 10.0204 15.6001 12.0004C15.6001 13.9804 13.9801 15.6004 12.0001 15.6004Z',
    },
    direction: 'up',
    click: handleSettingsClick,
  }

  config.modeBarButtonsToAdd = [newButton]
  return (
    <>
      <Plot
        data={data}
        layout={layout}
        config={config}
        onClick={handlerPlotClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // placement={'right-end'}
        onClose={handleSettingsClose}
        // transition
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionComponent={DraggableGrow}
      >
        <Box m={2} width={300}>
          <Typography
            variant="h6"
            gutterBottom
            className={'handle'}
            style={{ cursor: 'move' }}
          >
            Diagram Settings
          </Typography>
          <Divider />
          <Box my={1}>
            <Typography gutterBottom>Bla-bla</Typography>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

const Panel = ({ plateifu }) => {
  return (
    <>
      <BPT plateifu={plateifu} type={'NII'} />
      <BPT2d plateifu={plateifu} type={'NII'} />
      <BPT plateifu={plateifu} type={'SII'} />
      <BPT2d plateifu={plateifu} type={'SII'} />
    </>
  )
}

export default Panel
