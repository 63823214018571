import React, { Fragment } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const Loader = ({ width = 230, height = 250 }) => (
  <Box
    width={230}
    height={250}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Box>
)

export const OutputSkeleton = ({ height = 40 }) => (
  <Fragment>
    <Skeleton height={height} />
    <Skeleton height={height} animation="wave" />
    <Skeleton height={height} />
    <Skeleton height={height} animation="wave" />
    <Skeleton height={height} />
    <Skeleton height={height} animation="wave" />
  </Fragment>
)

export const LoaderOverlay = ({ loading }) => {
  return (
    <>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(250,250,250,0.5 )"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  )
}
