import React, { Fragment } from 'react'
// import { Redirect } from 'react-router-dom'

import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SearchResults from './SearchResults.js'
import FormCone from './FormCone.js'
import FormQuery from './FormQuery.js'

// const queryString = require('query-string')

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    margin: '1rem',
  },
}))

const SearchContainer = (props) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid className={classes.container}>
        <Box className="px-3 rounded" boxShadow={3}>
          <FormQuery {...props} />
          <hr />
          <FormCone {...props} />
        </Box>
        <SearchResults />
      </Grid>
    </Fragment>
  )
}

export default React.memo(
  SearchContainer,
  (prevProps, nextProps) =>
    prevProps.location.pathname === nextProps.location.pathname
)
