import { combineReducers } from 'redux'
import {
  TARGET_FETCH_ROW,
  TARGET_SET_POINTER,
  TARGET_FETCH_REDUX_MAP,
  TARGET_FETCH_REDUX_SPECTRUM,
  TARGET_FETCH_DAP_SPECTRUM,
  TARGET_FETCH_DAP_MAPS_LIST,
  TARGET_UPDATE_DAP_MAPS_SELECTED,
  TARGET_FETCH_DAP_BPTS,
  TARGET_REDUX_MAP_SHOW_LOADER,
  TARGET_REDUX_MAP_HIDE_LOADER,
  TARGET_REDUX_SPECTRUM_SHOW_LOADER,
  TARGET_REDUX_SPECTRUM_HIDE_LOADER,
  TARGET_DAP_SPECTRUM_SHOW_LOADER,
  TARGET_DAP_SPECTRUM_HIDE_LOADER,
  TARGT_CHANGE_DAP_BINSCHEMA,
  TABLE_SET_QUERY_STRING,
  TABLE_FETCH_ROWS,
  TABLE_SHOW_ALERT,
  TABLE_HIDE_ALERT,
  TABLE_CHANGE_VIEW,
  TABLE_CHANGE_PAGESIZE,
  TABLE_CHANGE_PAGE,
  TABLE_CHANGE_ORDER,
  TABLE_FETCH_ANALYSIS_MAPS,
  TABLE_ADD_PLACEHOLDERS,
} from './actions.js'

const initialTargetState = {
  row: null,
  reduxMap: null,
  reduxMapStatus: null,
  reduxMapError: null,
  reduxMapLoading: false,
  reduxSpectrum: null,
  reduxSpectrumStatus: null,
  reduxSpectrumLoading: false,
  reduxSpectrumError: null,
  DAPSpectrum: null,
  DAPSpectrumStatus: null,
  DAPSpectrumLoading: false,
  DAPSpectrumError: null,
  DAPMapsSelected: [
    'BIN_SNR',
    'STELLAR_VEL',
    'STELLAR_SIGMA',
    'EMLINE_GVEL_Ha6564',
    'EMLINE_GSIGMA_Ha6564',
    'EMLINE_GFLUX_Ha6564',
  ],
  DAPMapsList: null,
  DAPMapsListStatus: null,
  DAPMapsListError: null,
  DAPBPTS: null,
  pointerXY: { x: 0, y: 0 },
  DAPBinSchemas: [
    { name: 'VOR10-MILESHC-MASTARSSP', available: true },
    { name: 'SPX-MILESHC-MASTARSSP', available: true },
    { name: 'HYB10-MILESHC-MASTARSSP', available: false },
    { name: 'HYB10-MILESHC-MASTARHC2', available: false },
  ],
  DAPBinSchemaSelected: 'VOR10-MILESHC-MASTARSSP',
}

const targetReducer = (state = initialTargetState, action) => {
  switch (action.type) {
    case TARGET_FETCH_ROW:
      return { ...state, row: action.payload }
    case TARGET_FETCH_REDUX_MAP:
      if (action.payload.status === 'ok') {
        return {
          ...state,
          reduxMap: action.payload.data.redux_map,
          reduxMapStatus: action.payload.status,
        }
      } else {
        return {
          ...state,
          reduxMap: null,
          reduxMapStatus: action.payload.status,
          reduxMapError: action.payload.error,
        }
      }
    case TARGET_REDUX_MAP_SHOW_LOADER:
      return { ...state, reduxMapLoading: true }
    case TARGET_REDUX_MAP_HIDE_LOADER:
      return { ...state, reduxMapLoading: false }
    case TARGET_FETCH_REDUX_SPECTRUM:
      if (action.payload.status === 'ok') {
        return {
          ...state,
          reduxSpectrum: action.payload.data.redux_spectrum,
          reduxSpectrumStatus: action.payload.status,
        }
      } else {
        return {
          ...state,
          reduxSpectrum: null,
          reduxSpectrumStatus: action.payload.status,
          reduxSpectrumError: action.payload.error,
        }
      }
    case TARGET_FETCH_DAP_SPECTRUM:
      if (action.payload.status === 'ok') {
        return {
          ...state,
          DAPSpectrum: action.payload.data.dap_spectrum,
          DAPSpectrumStatus: action.payload.status,
        }
      } else {
        return {
          ...state,
          DAPSpectrum: null,
          DAPSpectrumStatus: action.payload.status,
          DAPSpectrumError: action.payload.error,
        }
      }
    case TARGET_FETCH_DAP_MAPS_LIST:
      if (action.payload.status === 'ok') {
        return {
          ...state,
          DAPMapsList: action.payload.data,
          DAPMapsListStatus: action.payload.status,
        }
      } else {
        return {
          ...state,
          DAPMapsList: action.payload.data,
          DAPMapsListStatus: action.payload.status,
          DAPMapsListError: action.payload.error,
        }
      }
    case TARGET_FETCH_DAP_BPTS:
      return { ...state, DAPBPTS: action.payload }
    case TARGET_UPDATE_DAP_MAPS_SELECTED:
      return { ...state, DAPMapsSelected: action.payload }
    case TARGET_SET_POINTER:
      return { ...state, pointerXY: action.payload }
    case TARGET_REDUX_SPECTRUM_SHOW_LOADER:
      return { ...state, reduxSpectrumLoading: true }
    case TARGET_REDUX_SPECTRUM_HIDE_LOADER:
      return { ...state, reduxSpectrumLoading: false }
    case TARGET_DAP_SPECTRUM_SHOW_LOADER:
      return { ...state, DAPSpectrumLoading: true }
    case TARGET_DAP_SPECTRUM_HIDE_LOADER:
      return { ...state, DAPSpectrumLoading: false }
    case TARGT_CHANGE_DAP_BINSCHEMA:
      return { ...state, DAPBinSchemaSelected: action.payload.binschema }
    default:
      return state
  }
}

const initialTableState = {
  queryString: null,
  rows: [],
  status: null,
  alert: null,
  view: 'table',
  page: 1,
  pageSize: 20,
  npages: 1,
  first: 0,
  last: 0,
  orderColumn: -1,
  orderDirection: '',
  analysisMaps: {},
}

const paginate = (nrows, pageSize, pageNumber) => {
  // Function to paginate an array of objects
  return {
    first: (pageNumber - 1) * pageSize,
    last: pageNumber * pageSize,
    npages: Math.ceil(nrows / pageSize),
  }
}

const tableReducer = (state = initialTableState, action) => {
  let p
  switch (action.type) {
    case TABLE_SET_QUERY_STRING:
      return { ...state, queryString: action.payload }
    case TABLE_SHOW_ALERT:
      return { ...state, alert: action.payload }
    case TABLE_HIDE_ALERT:
      return { ...state, alert: null }
    case TABLE_CHANGE_VIEW:
      return { ...state, view: action.payload }
    case TABLE_CHANGE_ORDER:
      return {
        ...state,
        orderColumn: action.payload.column,
        orderDirection: action.payload.direction,
      }
    case TABLE_CHANGE_PAGESIZE:
      p = paginate(state.rows.length, action.payload, state.page)
      return {
        ...state,
        pageSize: action.payload,
        npages: p.npages,
        first: p.first,
        last: p.last,
      }
    case TABLE_CHANGE_PAGE:
      p = paginate(state.rows.length, state.pageSize, action.payload)
      return { ...state, page: action.payload, first: p.first, last: p.last }
    case TABLE_FETCH_ROWS:
      p = paginate(action.payload.data.length, state.pageSize, state.page)
      return {
        ...state,
        rows: action.payload.data,
        status: action.payload.status,
        npages: p.npages,
        first: p.first,
        last: p.last,
      }
    case TABLE_ADD_PLACEHOLDERS:
      let newanalysisMaps = {}
      action.payload.forEach((plateifu) => {
        if (!state.analysisMaps.hasOwnProperty(plateifu)) {
          newanalysisMaps[plateifu] = {}
        }
      })
      return {
        ...state,
        analysisMaps: { ...state.analysisMaps, ...newanalysisMaps },
      }
    case TABLE_FETCH_ANALYSIS_MAPS:
      if (action.payload.status === 'ok') {
        return {
          ...state,
          analysisMaps: {
            ...state.analysisMaps,
            [action.payload.plateifu]: {
              data: action.payload.data.mapsData,
              status: action.payload.status,
            },
          },
        }
      } else {
        return {
          ...state,
          analysisMaps: {
            ...state.analysisMaps,
            [action.payload.plateifu]: {
              status: action.payload.status,
            },
          },
        }
      }
    default:
      return state
  }
}

export const rootReducer = combineReducers({
  table: tableReducer,
  target: targetReducer,
})
