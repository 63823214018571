import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDapMapSelected } from '../../../redux/actions.js'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

const TargetSettings = ({ open, handleClose }) => {
  const mapsList = useSelector((state) => state.target.DAPMapsList)
  const mapsSelected = useSelector((state) => state.target.DAPMapsSelected)
  const dispatch = useDispatch()

  return (
    <Dialog open={open} onClose={() => handleClose(false)} maxWidth="md">
      <DialogTitle className={'handle'}>
        {/* <Typography variant="h3">Settings</Typography> */}
        Settings
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Blocks</Typography>
        <FormControlLabel
          control={<Checkbox checked={false} />}
          label="Aladin"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="Legacy Survey Image"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="SDSS Image"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="DAP parameters"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="DAP maps"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="DAP spectrum"
        />
        <FormControlLabel
          control={<Checkbox checked={false} />}
          label="DRP spectrum"
        />
        <FormControlLabel
          control={<Checkbox checked={false} />}
          label="DRP flux map"
        />
        <FormControlLabel
          control={<Checkbox checked={true} />}
          label="NBursts results"
        />
        <hr />

        {mapsList ? (
          <Autocomplete
            multiple
            id="tags-outlined"
            options={mapsList}
            getOptionLabel={(option) => option}
            // defaultValue={}
            value={mapsSelected}
            filterSelectedOptions
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // label="Select More Maps"
                placeholder="Select more DAP maps"
              />
            )}
            onChange={(event, value, reason) => {
              dispatch(updateDapMapSelected(value))
            }}
          />
        ) : (
          'Maps list is loading...'
        )}
        <Typography display="inline">
          See maps description{' '}
          <a
            href="https://data.sdss.org/datamodel/files/MANGA_SPECTRO_ANALYSIS/DRPVER/DAPVER/DAPTYPE/PLATE/IFU/manga-MAPS-DAPTYPE.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            here.
          </a>
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default TargetSettings
