import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Typography, Box, makeStyles } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import SpeedDial from '@material-ui/lab/SpeedDial'

import SearchID from '../SearchID.js'
import TargetMain from './TargetMain.js'
import TargetSettings from './TargetSettings.js'

const useStyles = makeStyles((theme) => ({
  search: {
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}))

const TargetWithSettings = ({ plateifu }) => {
  const classes = useStyles()
  const [openSettings, setOpenSettings] = useState(false)

  return (
    <>
      <TargetMain plateifu={plateifu} />
      <TargetSettings open={openSettings} handleClose={setOpenSettings} />

      <SpeedDial
        ariaLabel="Settings"
        className={classes.speedDial}
        // hidden={hidden}
        icon={<SettingsIcon fontSize="large" />}
        onClick={() => setOpenSettings(true)}
        // onClose={handleClose}
        // onOpen={handleOpen}
        // open={open}
        open={openSettings}
        // direction={direction}
      ></SpeedDial>
    </>
  )
}

const TargetPicker = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Pick a target
      </Typography>
      <SearchID className={classes.search} />
      <Typography variant="body1">
        Use comprehensive <Link to="search/">Search form</Link>
      </Typography>
    </>
  )
}

const VisualiserLanding = () => {
  const { plateifu } = useParams()
  return (
    <>
      {plateifu ? (
        <TargetWithSettings plateifu={plateifu} />
      ) : (
        <Container>
          <Box mt={3}>
            <TargetPicker />
          </Box>
        </Container>
      )}
    </>
  )
}

export default VisualiserLanding
