import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { SDSS, LegacySurvey } from '../visualiser/Cutouts.js'
import { MapsBasicContainer } from '../visualiser/MapsBasicContainer.js'

const useStyles = makeStyles((theme) => ({
  panelRow: {
    marginBottom: 10,
  },
  infoBox: {
    paddingLeft: 10,
    width: 230,
    height: 230,
  },
  imageBox: {
    marginRight: 10,
  },
  mapsBox: {
    verticalAlign: 'top',
  },
  imageHidden: {
    display: 'none',
  },
  imageVisible: {
    display: 'inline',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)'
  },
}))

const Info = ({ row }) => (
  <Fragment>
    <b>MaNGA ID:</b> {row.mangaid}
    <br />
    <b>Plate-IFU: </b>
    <NavLink
      to={`/visualiser/${row.plateifu}`}
      // target="_blank"
    >
      <strong>{row.plateifu}</strong>
    </NavLink>
    <br />
    <b>&alpha;, &delta;:</b> {`${row.ifura}, ${row.ifudec}`}
    <br />
    <b>z:</b> {row.z ? row.z : 'N/A'}
    <br />
    <b>
      S/N<sub>blue</sub>:
    </b>{' '}
    {row.bluesn2.toFixed(2)}
    <br />
    <b>
      S/N<sub>red</sub>:
    </b>{' '}
    {row.redsn2.toFixed(2)}
    <br />
    <b>
      M<sub>star</sub> (M<sub>sun</sub>):
    </b>{' '}
    {row.nsa_elpetro_mass ? row.nsa_elpetro_mass.toExponential(2) : 'N/A'}
    <br />
    <b>
      R<sub>eff</sub> (''):
    </b>{' '}
    {row.nsa_elpetro_th50_r ? row.nsa_elpetro_th50_r.toFixed(2) : 'N/A'}
    <br />
    <b>Axis ratio b/a:</b>{' '}
    {row.nsa_elpetro_ba ? row.nsa_elpetro_ba.toFixed(2) : 'N/A'}
  </Fragment>
)

const PanelObject = ({ row }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box
        display="inline-block"
        width={210}
        position="relative"
        top={-25}
        marginLeft="1rem"
      >
        <Info row={row} />
      </Box>
      {/* <Box display="inline-block" marginRight={1}>
        <SDSS ra={row.ifura} dec={row.ifudec} />
      </Box>
      <Box display="inline-block">
        <LegacySurvey ra={row.ifura} dec={row.ifudec} />
      </Box> */}
      <Box display="inline-block" className={classes.mapsBox}>
        <MapsBasicContainer plateifu={row.plateifu} />
      </Box>
    </Box>
  )
}

export default PanelObject
