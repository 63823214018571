// import * as React from "react";
import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { Button, Typography, Grid } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'formik-material-ui'
import { useDispatch } from 'react-redux'
import { tableHideAlert, tableFetchRows } from '../../redux/actions.js'
import * as queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  title: {
    width: '170px',
  },
  query: {
    marginLeft: '1rem',
    flexGrow: 1,
  },
}))

const validateQuery = (value) => {
  let error
  if (value === undefined || value.length === 0) {
    error = 'Cannot be empty!'
  }
  return error
}

const FormQuery = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const qs = queryString.parse(props.location.search)
  const query = 'q' in qs ? qs.q : ''

  const handleChange = (values) => {
    props.history.push({
      ...props.location,
      search: queryString.stringify({
        q: values.queryString,
      }),
    })
  }

  return (
    <Formik
      initialValues={{ queryString: query }}
      validate={handleChange}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(tableHideAlert())
        dispatch(tableFetchRows(values.queryString))
        setSubmitting(false)
      }}
    >
      {({ values, submitForm, isSubmitting }) => {
        return (
          <Form>
            <Grid container alignItems="flex-end" justify="space-between">
              <Grid item className={classes.title}>
                <Typography variant="h5">
                  Query Search
                  <Link to="/docs">
                    <HelpOutlineIcon fontSize="small" className="ml-1" />
                  </Link>
                </Typography>
              </Grid>
              <Grid item className={classes.query}>
                <Field
                  component={TextField}
                  name="queryString"
                  type="text"
                  label="Enter you search query"
                  validate={validateQuery}
                  fullWidth
                ></Field>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className="ml-3 mt-3"
                >
                  Search
                </Button>
                <Button
                  // type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className="ml-3 mt-3"
                  onClick={(event) => dispatch(tableFetchRows(''))}
                >
                  Show All
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormQuery
