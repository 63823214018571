// This module prepares minimal required bundle for using plotly
import Plotly from 'plotly.js/lib/core'
import createPlotlyComponent from 'react-plotly.js/factory'

Plotly.register([
  require('plotly.js/lib/heatmap'),
  require('plotly.js/lib/contour'),
])

const Plot = createPlotlyComponent(Plotly)

export default Plot
