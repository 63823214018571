import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import { AppBar, Tab, Tabs, colors, Typography, Box } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import HomeIcon from '@material-ui/icons/Home'
// import PersonIcon from '@material-ui/icons/Person'
// import ContactsIcon from '@material-ui/icons/Contacts'
import PeopleIcon from '@material-ui/icons/People'

import { makeStyles } from '@material-ui/core/styles'

import Home from './Home.js'
import Search from './search/Search.js'
import Target from './visualiser/targetPage/TargetPage.js'
import Docs from './docs/Docs.js'
// import About from './About.js'
import Login from './Login.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    textTransform: 'capitalize',
  },
  logoImg: {
    height: '30px',
  },
  bottom: {
    root: {
      marginRight: '10px',
    },
  },
  icon: {
    marginRight: '4px',
  },
  title: {
    margin: '5px',
  },
  appbar: {
    background: '#313131',
  },
  tab: {
    '&:hover': {
      color: colors.grey[50],
      background: colors.grey[700],
    },
  },
  tabIndicator: {
    backgroundColor: colors.grey[50],
    height: '3px',
  },
}))

const Main = () => {
  const classes = useStyles()

  return (
    <>
      <Route
        path="/"
        render={({ location }) => {
          const rootPath = '/' + location.pathname.split('/')[1]
          return (
            <>
              <div className={'content'}>
                <AppBar className={classes.appbar} position="static">
                  <Tabs
                    value={rootPath}
                    TabIndicatorProps={{ className: classes.tabIndicator }}
                  >
                    <Tab
                      label={
                        <div>
                          <HomeIcon
                            fontSize="inherit"
                            style={{ verticalAlign: 'middle' }}
                          />{' '}
                          Home
                        </div>
                      }
                      value="/"
                      to="/"
                      component={Link}
                      className={classes.tab}
                    />
                    <Tab
                      label={
                        <div>
                          <SearchIcon
                            fontSize="inherit"
                            style={{ verticalAlign: 'middle' }}
                          />{' '}
                          Search
                        </div>
                      }
                      value="/search"
                      to="/search"
                      component={Link}
                      className={classes.tab}
                    />
                    <Tab
                      label={
                        <div>
                          <ShowChartIcon
                            fontSize="inherit"
                            style={{ verticalAlign: 'middle' }}
                          />{' '}
                          Visualiser
                        </div>
                      }
                      value="/visualiser"
                      to="/visualiser"
                      component={Link}
                      className={classes.tab}
                    />
                    <Tab
                      label={
                        <div>
                          <MenuBookIcon
                            fontSize="inherit"
                            style={{ verticalAlign: 'middle' }}
                          />{' '}
                          Docs
                        </div>
                      }
                      value="/docs"
                      to="/docs"
                      component={Link}
                      className={classes.tab}
                    />
                    <Tab
                      label={
                        <div>
                          <PeopleIcon
                            fontSize="inherit"
                            style={{ verticalAlign: 'middle' }}
                          />{' '}
                          About
                        </div>
                      }
                      value="/about"
                      to="/about"
                      component={Link}
                      className={classes.tab}
                    />
                    {/* <Tab
                      label={
                        <div>
                          <PersonIcon style={{ verticalAlign: 'top' }} /> Login
                        </div>
                      }
                      value="/login"
                      to="/login"
                      component={Link}
                      className={classes.tab}
                    /> */}
                  </Tabs>
                </AppBar>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/search" component={Search} />
                  <Route path="/visualiser/:plateifu?" component={Target} />
                  <Route path="/docs" component={Docs} />
                  <Route path="/login" component={Login} />
                </Switch>
              </div>
              <footer>
                <Box
                  display="flex"
                  justifyContent="center"
                  mx={1}
                  px={1}
                  bgcolor="background.paper"
                >
                  <Box p={1}>
                    <Typography>
                      &copy; 2020-2022{' '}
                      <a href="https://ivan-katkov.github.io/">Ivan Katkov</a>
                    </Typography>
                  </Box>
                </Box>
              </footer>
            </>
          )
        }}
      />
    </>
  )
}

export default Main
