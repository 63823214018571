import React, { useState, useEffect, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { targetFetchDAPSpectrum } from '../../../redux/actions.js'
import Plot from '../../custom-plotly.js'
import {
  Popover,
  Grow,
  Box,
  Typography,
  Slider,
  Grid,
  FormGroup,
  FormControlLabel,
  Input,
  Checkbox,
  Divider,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import d3 from 'd3'
import { roundValue, round10Value } from '../../utils.js'

const DraggableWrapper = forwardRef(({ children, ...other }, ref) => {
  return (
    <Draggable ref={ref} handle=".handle">
      {React.cloneElement(children, { ...other })}
    </Draggable>
  )
})

const DraggableGrow = forwardRef(({ children, ...other }, ref) => {
  return (
    <Grow {...other} timeout={0}>
      <DraggableWrapper ref={ref}>{children}</DraggableWrapper>
    </Grow>
  )
})

const TargetDAPSpectrum = ({ plateifu, specPlotDAPID, specPlotSize, emis }) => {
  const defaultRangeX = [3600.0, 8500.0]
  const pointXY = useSelector((state) => state.target.pointerXY)
  const spec = useSelector((state) => state.target.DAPSpectrum)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [rangeXMin, setRangeXMin] = useState(defaultRangeX[0])
  const [rangeXMax, setRangeXMax] = useState(defaultRangeX[1])
  const [rangeFullXMin, setRangeFullXMin] = useState(3000)
  const [rangeFullXMax, setRangeFullXMax] = useState(11000)
  const [rangeYMin, setRangeYMin] = useState(0)
  const [rangeYMax, setRangeYMax] = useState(1)
  const [rangeFullY, setRangeFullY] = useState([0, 1])
  const [isPercentageRange, setIsPercentageRange] = useState(true)
  const [percentageY, setPercentageY] = useState(99.9)
  const [isRangeAdaptive, setIsRangeAdaptive] = useState(true)
  const [rms, setRMS] = useState(0)
  const errLevelRMS = emis ? -3 : 0

  useEffect(() => {
    dispatch(targetFetchDAPSpectrum(plateifu, pointXY))
  }, [dispatch, plateifu, pointXY])

  useEffect(() => {
    if (spec) {
      // fragment of the spectrum available shown in the plot
      const flux = emis
        ? spec.stars.map((v, i) => (v === null ? null : spec.flux[i] - v))
        : spec.flux
      const subSpec = flux.filter(
        (e, i) =>
          spec.wave[i] >= rangeXMin && spec.wave[i] <= rangeXMax && e !== null
      )
      const subResid = spec.resid.filter(
        (e, i) =>
          spec.wave[i] >= rangeXMin && spec.wave[i] <= rangeXMax && e !== null
      )
      const subSpecSorted = [...subSpec].sort()
      const rms = d3.deviation(subResid)
      setRMS(rms)
      const minmax = d3.extent(subSpecSorted)

      setRangeFullY([
        // roundValue(minmax[0] - 0.5 * (minmax[1] - minmax[0])),
        -10 * rms,
        roundValue(minmax[1] + 0.5 * (minmax[1] - minmax[0])),
      ])
      if (isRangeAdaptive) {
        if (isPercentageRange) {
          setRangeYMin(roundValue(errLevelRMS * rms - 3 * rms))
          setRangeYMax(
            roundValue(d3.quantile(subSpecSorted, percentageY / 100.0))
          )
        } else {
          setRangeYMin(roundValue(errLevelRMS * rms - 3 * rms))
          setRangeYMax(roundValue(minmax[1]))
        }
      }
    }
  }, [
    spec,
    rangeXMin,
    rangeXMax,
    isPercentageRange,
    percentageY,
    isRangeAdaptive,
    errLevelRMS,
    emis,
  ])

  const handleSettingsClick = (event) => {
    setAnchorEl(event)
  }

  const handleSettingsClose = (event) => {
    setAnchorEl(null)
  }

  const handleChangeRangeX = (event, newValue) => {
    setRangeXMin(newValue[0])
    setRangeXMax(newValue[1])
  }

  const handleChangeRangeY = (event, newValue) => {
    setRangeYMin(newValue[0])
    setRangeYMax(newValue[1])
  }

  const handleInputRangeXMin = (event) => {
    setRangeXMin(event.target.value)
    if (Number(event.target.value) < Number(rangeFullXMin)) {
      setRangeFullXMin(event.target.value)
    }
  }

  const handleInputRangeXMax = (event) => {
    setRangeXMax(event.target.value)
    if (Number(event.target.value) > Number(rangeFullXMax)) {
      setRangeFullXMax(event.target.value)
    }
  }

  const handleInputRangeYMin = (event) => {
    setRangeYMin(event.target.value)
  }

  const handleInputRangeYMax = (event) => {
    setRangeYMax(event.target.value)
  }

  const handlePlotRelayout = (gd) => {
    if (gd.hasOwnProperty('xaxis.range[0]')) {
      setRangeXMin(gd['xaxis.range[0]'])
      setRangeXMax(gd['xaxis.range[1]'])
    }
    if (gd.hasOwnProperty('xaxis.autorange')) {
      setRangeXMin(defaultRangeX[0])
      setRangeXMax(defaultRangeX[1])
    }
    if (gd.hasOwnProperty('yaxis.range[0]') && !isRangeAdaptive) {
      setRangeYMin(roundValue(gd['yaxis.range[0]']))
      setRangeYMax(roundValue(gd['yaxis.range[1]']))
    }
  }

  const stepY = (rangeFullY[1] - rangeFullY[0]) / 100
  const rangeY = [
    Number(rangeYMin) ? Number(rangeYMin) : null,
    Number(rangeYMax) ? Number(rangeYMax) : null,
  ]

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let data
  if (spec) {
    const emFlux = spec.stars.map((v, i) =>
      v === null ? null : spec.flux[i] - v
    )
    const emFit = spec.stars.map((v, i) =>
      v === null ? null : spec.fit[i] - v
    )

    if (emis) {
      data = [
        {
          name: 'Flux Emis.',
          x: spec.wave,
          y: emFlux,
          line: { width: 1, color: 'black' },
          hoverinfo: 'skip',
        },
        {
          name: 'Model',
          x: spec.wave,
          y: emFit,
          line: { width: 2.0, color: 'red' },
          hoverinfo: 'skip',
        },
        {
          name: 'Residuals',
          x: spec.wave,
          y: spec.resid.map((v) => errLevelRMS * rms + v),
          line: { width: 1, color: 'grey' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
        },
        {
          name: 'Error',
          x: spec.wave,
          y: spec.err.map((v) => errLevelRMS * rms + v),
          line: { width: 0.5, color: 'blue' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
        },
        {
          // name: 'Error',
          x: spec.wave,
          y: spec.errm.map((v) => errLevelRMS * rms + v),
          line: { width: 0.5, color: 'blue' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
          showlegend: false,
        },
      ]
    } else {
      data = [
        {
          name: 'Flux',
          x: spec.wave,
          y: spec.flux,
          line: { width: 1, color: 'black' },
          hoverinfo: 'skip',
        },
        {
          name: 'St. model',
          x: spec.wave,
          y: spec.stars,
          line: { width: 1.5, color: 'orange' },
          hoverinfo: 'skip',
        },
        {
          name: 'Model',
          x: spec.wave,
          y: spec.fit,
          line: { width: 2.0, color: 'red' },
          hoverinfo: 'skip',
        },
        {
          name: 'Residuals',
          x: spec.wave,
          y: spec.resid.map((v) => errLevelRMS * rms + v),
          line: { width: 1, color: 'grey' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
        },
        {
          name: 'Error',
          x: spec.wave,
          y: spec.err.map((v) => errLevelRMS * rms + v),
          line: { width: 0.5, color: 'blue' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
        },
        {
          // name: 'Error',
          x: spec.wave,
          y: spec.errm.map((v) => errLevelRMS * rms + v),
          line: { width: 0.5, color: 'blue' },
          // marker: { color: 'grey', size: 5 },
          hoverinfo: 'skip',
          showlegend: false,
        },
      ]
    }
  } else data = null

  const margin = { l: 50, r: 0, b: 50, t: 25, pad: 5 }
  const layout = {
    // title: `Spectrum at x=${pointXY.x} y=${pointXY.y}`,
    autosize: true,
    margin: margin,
    xaxis: {
      title: 'Wavelengths, A',
      range: [rangeXMin, rangeXMax],
      tickformat: '.0',
    },
    yaxis: {
      title: 'Flux, 10<sup>-17</sup>erg/s/cm<sup>2</sup>/A',
      range: rangeY,
    },
    legend: { orientation: 'h', x: 0, y: 1.05 },
  }
  // Add new button into thee Plotly Menu Bar
  const newButton = {
    name: 'Plot settings',
    icon: {
      width: 24,
      height: 24,
      viewBox: [0, 0, 24, 24],
      path:
        'M19.1401 12.9404C19.1801 12.6404 19.2001 12.3304 19.2001 12.0004C19.2001 11.6804 19.1801 11.3604 19.1301 11.0604L21.1601 9.48039C21.3401 9.34039 21.3901 9.07039 21.2801 8.87039L19.3601 5.55039C19.2401 5.33039 18.9901 5.26039 18.7701 5.33039L16.3801 6.29039C15.8801 5.91039 15.3501 5.59039 14.7601 5.35039L14.4001 2.81039C14.3601 2.57039 14.1601 2.40039 13.9201 2.40039H10.0801C9.84011 2.40039 9.65011 2.57039 9.61011 2.81039L9.25011 5.35039C8.66011 5.59039 8.12011 5.92039 7.63011 6.29039L5.24011 5.33039C5.02011 5.25039 4.77011 5.33039 4.65011 5.55039L2.74011 8.87039C2.62011 9.08039 2.66011 9.34039 2.86011 9.48039L4.89011 11.0604C4.84011 11.3604 4.80011 11.6904 4.80011 12.0004C4.80011 12.3104 4.82011 12.6404 4.87011 12.9404L2.84011 14.5204C2.66011 14.6604 2.61011 14.9304 2.72011 15.1304L4.64011 18.4504C4.76011 18.6704 5.01011 18.7404 5.23011 18.6704L7.62011 17.7104C8.12011 18.0904 8.65011 18.4104 9.24011 18.6504L9.60011 21.1904C9.65011 21.4304 9.84011 21.6004 10.0801 21.6004H13.9201C14.1601 21.6004 14.3601 21.4304 14.3901 21.1904L14.7501 18.6504C15.3401 18.4104 15.8801 18.0904 16.3701 17.7104L18.7601 18.6704C18.9801 18.7504 19.2301 18.6704 19.3501 18.4504L21.2701 15.1304C21.3901 14.9104 21.3401 14.6604 21.1501 14.5204L19.1401 12.9404ZM12.0001 15.6004C10.0201 15.6004 8.40011 13.9804 8.40011 12.0004C8.40011 10.0204 10.0201 8.40039 12.0001 8.40039C13.9801 8.40039 15.6001 10.0204 15.6001 12.0004C15.6001 13.9804 13.9801 15.6004 12.0001 15.6004Z',
    },
    direction: 'up',
    click: handleSettingsClick,
  }
  const buttonsToRemove = [
    // 'zoom2d',
    // 'pan2d',
    'select2d',
    'lasso2d',
    // 'zoomIn2d',
    // 'zoomOut2d',
    'autoScale2d',
    // 'resetScale2d',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'resetViews',
    // 'toImage',
    'sendDataToCloud',
    'toggleSpikelines',
    'resetViewMapbox',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
  ]

  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToAdd: [newButton],
    modeBarButtonsToRemove: buttonsToRemove,
  }

  return (
    <>
      <Plot
        ref={specPlotDAPID}
        data={data}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: specPlotSize[0], height: specPlotSize[1] }}
        onRelayout={handlePlotRelayout}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // placement={'right-end'}
        onClose={handleSettingsClose}
        // transition
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionComponent={DraggableGrow}
      >
        <Box m={2} width={300}>
          <Typography
            variant="h6"
            gutterBottom
            className={'handle'}
            style={{ cursor: 'move' }}
          >
            Plot Settings
          </Typography>
          <Divider />
          <Box>
            <Typography gutterBottom variant="h6">
              Flux range
            </Typography>
            <FormControlLabel
              p={0}
              control={
                <>
                  <Checkbox
                    checked={isRangeAdaptive}
                    onChange={() => setIsRangeAdaptive(!isRangeAdaptive)}
                    name="isRangeAdaptive"
                    color="primary"
                  />
                </>
              }
              label="Use adaptive range"
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPercentageRange}
                    onChange={() => setIsPercentageRange(!isPercentageRange)}
                    name="isPercentageRange"
                    color="primary"
                  />
                }
                label="Use percentage level"
              />
              <FormControlLabel
                control={
                  <Box ml={2}>
                    <Input
                      value={percentageY}
                      margin="dense"
                      onChange={(e) => setPercentageY(e.target.value)}
                      inputProps={{
                        type: 'number',
                        step: 0.01,
                        min: 50,
                        max: 150,
                      }}
                    />
                  </Box>
                }
              />
            </FormGroup>

            <Box>
              <Slider
                // value={[roundValue(rangeYMin), roundValue(rangeYMax)]}
                value={rangeY}
                min={rangeFullY[0]}
                max={rangeFullY[1]}
                step={round10Value(stepY, 1)}
                onChange={handleChangeRangeY}
                valueLabelDisplay="auto"
              />
              <Grid
                container
                // alignItems="baseline"
                justify="flex-start"
                spacing={2}
              >
                <Grid item xs>
                  <Input
                    value={rangeYMin}
                    margin="dense"
                    onChange={handleInputRangeYMin}
                    fullWidth
                    inputProps={{
                      type: 'number',
                      step: round10Value(stepY, 1),
                      min: rangeFullY[0],
                      max: rangeFullY[1],
                      // style: { padding: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Input
                    value={rangeYMax}
                    margin="dense"
                    onChange={handleInputRangeYMax}
                    fullWidth
                    inputProps={{
                      type: 'number',
                      step: round10Value(stepY, 1),
                      min: rangeFullY[0],
                      max: rangeFullY[1],
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box mt={2}>
            <Typography gutterBottom variant="h6">
              Wavelength range
            </Typography>
            <Slider
              value={[Number(rangeXMin), Number(rangeXMax)]}
              min={rangeFullXMin === '' ? null : Number(rangeFullXMin)}
              max={rangeFullXMax === '' ? null : Number(rangeFullXMax)}
              // step={stepX50_round}
              onChange={handleChangeRangeX}
              valueLabelDisplay="auto"
            />
            <Grid
              container
              // alignItems="baseline"
              justify="flex-start"
              spacing={2}
            >
              <Grid item xs>
                <Input
                  value={roundValue(rangeXMin, 5)}
                  margin="dense"
                  onChange={handleInputRangeXMin}
                  fullWidth
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item xs>
                <Input
                  value={roundValue(rangeXMax, 5)}
                  margin="dense"
                  onChange={handleInputRangeXMax}
                  fullWidth
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default TargetDAPSpectrum
