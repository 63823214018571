import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tableHideAlert } from '../../redux/actions.js'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { TableBoard } from './TableBoard.js'

const Loader = () => (
  <Box height="40vh" display="flex" justifyContent="center" alignItems="center">
    <Box width="40vw">
      <Typography variant="h5" align="center">
        Loading data
      </Typography>
      <LinearProgress />
    </Box>
  </Box>
)

const SearchResults = () => {
  const rows = useSelector((state) => state.table.rows)
  const status = useSelector((state) => state.table.status)
  const alert = useSelector((state) => state.table.alert)
  const dispatch = useDispatch()

  return (
    <Fragment>
      <Box className="mt-4 rounded" boxShadow={3}>
        {alert && (
          <Alert
            severity={alert.severity}
            onClose={() => dispatch(tableHideAlert())}
          >
            {alert.severity === 'error' && <AlertTitle>Error</AlertTitle>}
            {alert.severity === 'warning' && <AlertTitle>Warning</AlertTitle>}
            {alert.severity === 'info' && <AlertTitle>Info</AlertTitle>}
            {alert.text}
          </Alert>
        )}
        {/* {status === 'loading' && <OutputSkeleton />} */}
        {status === 'loading' && <Loader />}
        {rows.length > 0 && (
          <Fragment>
            {/* <ControlTablePanel /> */}
            <TableBoard />
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default SearchResults
