import React from "react";
import { Typography, Container, Box } from "@material-ui/core";

function Login() {
    return (
        <Container>
            <Box mt={3}>
                <Typography variant="h2" gutterBottom>Login</Typography>
                <Typography variant="body1">Coming soon...</Typography>
            </Box>
        </Container>
    )
};

export default Login;