import React from 'react'
import InfoQueryLanguage from './InfoQueryLanguage.js'
import { Container, Typography } from '@material-ui/core'

function Docs() {
  return (
    <Container className="mt-4">
      <Typography variant="h2" gutterBottom>
        Queries
      </Typography>

      <InfoQueryLanguage />
    </Container>
  )
}

export default Docs
