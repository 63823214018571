import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, FormControlLabel, Switch } from '@material-ui/core'
import TargetDAPSpectrum from './TargetDAPSpectrum'
import { LoaderOverlay } from '../../Loaders'

const TargetDAP = (props) => {
  const loadingSpec = useSelector((state) => state.target.DAPSpectrumLoading)
  const [emis, setEmis] = useState(false)

  return (
    <Box position="relative" mx={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography
          variant="h6"
          className="DragHandle"
          style={{ cursor: 'move' }}
        >
          DAP Spectral Cube
        </Typography>

        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={emis}
              onChange={(ev) => setEmis(!emis)}
            />
          }
          label="Show emission spectrum only"
        />
      </Box>
      <TargetDAPSpectrum
        plateifu={props.plateifu}
        specPlotDAPID={props.specPlotDAPID}
        specPlotSize={props.specPlotSize}
        emis={emis}
      />
      <LoaderOverlay loading={loadingSpec} />
    </Box>
  )
}

export default TargetDAP
