import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { colors } from '@material-ui/core'
import './App.css'
import Main from './components/Main.js'
import { initializeGA, withTracker } from './analyticsTracker'

initializeGA()

// let theme = createMuiTheme()
let theme = createMuiTheme({
  palette: {
    primary: {
      light: colors.grey[400],
      main: colors.grey[700],
      dark: colors.grey[800],
    },
    secondary: {
      light: colors.pink[400],
      main: colors.pink[700],
      dark: colors.pink[900],
    },
  },
  overrides: {
    MuiAppBar: {
      root: { height: 40, 'min-height': 40 },
    },
    MuiTabs: {
      root: { height: 40, 'min-height': 40 },
    },
    MuiTab: {
      root: { height: 40, 'min-height': 40 },
    },
  },
})

const remToPix = (value) => {
  return Math.round(parseFloat(value) * 16)
}

const vars = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'button',
  'caption',
  'overline',
]

vars.forEach((variant) => {
  const style = theme.typography[variant]

  const pixelFontSize = remToPix(style.fontSize)

  theme.typography[variant] = {
    ...style,
    fontSize: pixelFontSize * 0.9,
  }
})
// theme = responsiveFontSizes(theme, { factor: 5, disableAlign: true })

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  )
}

export default withTracker(App)
