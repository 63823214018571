import React, { useState, useRef, useEffect } from 'react'
import { Typography, makeStyles, Box } from '@material-ui/core'
import { MapsBasicContainer } from '../MapsBasicContainer.js'
import TargetInfo from './TargetInfo'
import TargetImages from './TargetImages'
// import TargetDRP from './TargetDRP'
import TargetDAP from './TargetDAP'
import TargetGasDiagnoostics from './TargetGasDiagnostics'
// import GridLayout from 'react-grid-layout'
import { Responsive, WidthProvider } from 'react-grid-layout'

import { useDispatch } from 'react-redux'
import {
  targetFetchDapBPTS,
  targetFetchDapMapsList,
  targetFetchRow,
} from '../../../redux/actions.js'

import 'react-grid-layout/css/styles.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    margin: '1rem',
  },
}))

const CELL_SIZE = 30
const SPEC_PLOT_MARGIN = [0.05, 0.1]
const SPEC_PLOT_WH = [22, 20]

const TargetMain = ({ plateifu }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [specPlotSize, setSpecPlotSize] = useState([
    CELL_SIZE * SPEC_PLOT_WH[0] * (1 - SPEC_PLOT_MARGIN[0]),
    CELL_SIZE * SPEC_PLOT_WH[1] * (1 - SPEC_PLOT_MARGIN[1]),
  ])
  const specPlotDAPID = useRef(null)
  const boxRef = useRef(null)

  const handleResize = (ev) => {
    specPlotDAPID.current.resizeHandler()
    setSpecPlotSize([
      parseInt(boxRef.current.style.width) * (1 - SPEC_PLOT_MARGIN[0]),
      parseInt(boxRef.current.style.height) * (1 - SPEC_PLOT_MARGIN[1]),
    ])
  }

  useEffect(() => {
    dispatch(targetFetchDapMapsList(plateifu))
  }, [dispatch, plateifu])

  useEffect(() => {
    setSpecPlotSize([
      boxRef.current.clientWidth * (1 - SPEC_PLOT_MARGIN[0]),
      boxRef.current.clientHeight * (1 - SPEC_PLOT_MARGIN[1]),
    ])
  }, [])

  return (
    <div className={classes.container}>
      <ResponsiveGridLayout
        className="layout"
        cols={{ lg: 40, md: 40, sm: 20, xs: 20 }}
        // breakpoints={{ lg: 1200}, md: 996, sm: 768, xs: 480, xxs: 0 }}
        // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={CELL_SIZE}
        draggableHandle=".DragHandle"
        onResizeStop={handleResize}
      >
        {/* <Box
          key="1"
          boxShadow={2}
          data-grid={{ x: 0, y: 0, w: 4, h: 2 }}
          overflow="scroll"
          display="flex"
          justifyContent="flex-start"
          p={2}
          className="DragHandle"
          style={{ cursor: 'move' }}
        >
          <TargetImages plateifu={plateifu} />
        </Box> */}

        <Box
          key="1"
          boxShadow={2}
          data-grid={{ x: 0, y: 0, w: 40, h: 4 }}
          overflow="scroll"
          p={2}
        >
          <TargetInfo plateifu={plateifu} />
        </Box>

        <Box
          key="2"
          boxShadow={2}
          data-grid={{ x: 0, y: 5, w: 18, h: 20 }}
          overflow="scroll"
        >
          <Box pl={1} pt={1}>
            <div className="DragHandle" style={{ cursor: 'move' }}>
              <Typography variant="h6">Images and DAP Maps</Typography>
            </div>
          </Box>
          <MapsBasicContainer plateifu={plateifu} />
        </Box>

        <Box
          key="4"
          data-grid={{ x: 19, y: 5, w: SPEC_PLOT_WH[0], h: SPEC_PLOT_WH[1] }}
          boxShadow={2}
          pl={1}
          pt={1}
          ref={boxRef}
        >
          <TargetDAP
            plateifu={plateifu}
            specPlotDAPID={specPlotDAPID}
            specPlotSize={specPlotSize}
          />
        </Box>

        {/* <Box
          key="5"
          boxShadow={2}
          data-grid={{ x: 0, y: 8, w: 9, h: 3 }}
          // isResizable={false}
          overflow="scroll"
        >
          <Box pl={1} pt={1}>
            <div className="DragHandle" style={{ cursor: 'move' }}>
              <Typography variant="h6">
                Data Reduction Products (MaNGA Cube)
              </Typography>
            </div>
          </Box>

          <TargetDRP plateifu={plateifu} />
        </Box> */}
        <Box
          key="5"
          boxShadow={2}
          data-grid={{ x: 0, y: 25, w: 40, h: 10 }}
          overflow="scroll"
        >
          <Box pl={1} pt={1}>
            <div className="DragHandle" style={{ cursor: 'move' }}>
              <Typography variant="h6">Ionized Gas Diagnostics</Typography>
            </div>
          </Box>
          <TargetGasDiagnoostics plateifu={plateifu} />
        </Box>

        <Box
          key="6"
          boxShadow={2}
          data-grid={{ x: 0, y: 40, w: 20, h: 5 }}
          // isResizable={false}
          overflow="scroll"
        >
          <Box pl={1} pt={1} className="DragHandle" style={{ cursor: 'move' }}>
            <Typography variant="h6">NBursts results</Typography>
            <Typography variant="subtitle1">Coming soon...</Typography>
          </Box>
        </Box>
      </ResponsiveGridLayout>
    </div>
  )
}

export default TargetMain
