// Action Types

export const TARGET_FETCH_ROW = 'TARGET/FETCH_ROW'
export const TARGET_FETCH_REDUX_MAP = 'TARGET/FETCH_REDUX_MAP'
export const TARGET_FETCH_REDUX_SPECTRUM = 'TARGET/FETCH_REDUX_SPECTRUM'
export const TARGET_FETCH_DAP_SPECTRUM = 'TARGET/FETCH_DAP_SPECTRUM'
export const TARGET_FETCH_DAP_MAPS_LIST = 'TARGET/FETCH_DAP_MAPS_LIST'
export const TARGET_FETCH_DAP_BPTS = 'TARGET/FETCH_DAP_BPTS'
export const TARGET_UPDATE_DAP_MAPS_SELECTED = 'TARGET/UPDATE_DAP_MAPS_SELECTED'
export const TARGET_REDUX_MAP_SHOW_LOADER = 'TARGET/REDUX_MAP_SHOW_LOADER'
export const TARGET_REDUX_MAP_HIDE_LOADER = 'TARGET/REDUX_MAP_HIDE_LOADER'

export const TARGET_SET_POINTER = 'TARGET/SET_POINTER'
export const TARGET_REDUX_SPECTRUM_SHOW_LOADER =
  'TARGET/REDUX_SPECTRUM_SHOW_LOADER'
export const TARGET_REDUX_SPECTRUM_HIDE_LOADER =
  'TARGET/REDUX_SPECTRUM_HIDE_LOADER'
export const TARGET_DAP_SPECTRUM_SHOW_LOADER = 'TARGET/DAP_SPECTRUM_SHOW_LOADER'
export const TARGET_DAP_SPECTRUM_HIDE_LOADER = 'TARGET/DAP_SPECTRUM_HIDE_LOADER'
export const TARGT_CHANGE_DAP_BINSCHEMA = 'TARGT/CHANGE_DAP_BINSCHEMA'

export const TABLE_SET_QUERY_STRING = 'TABLE/SET_QUERY_STRING'
export const TABLE_FETCH_ROWS = 'TABLE/FETCH_ROWS'
export const TABLE_CHANGE_VIEW = 'TABLE/CHANGE_VIEW'
export const TABLE_CHANGE_PAGESIZE = 'TABLE/CHANGE_NPAGES'
export const TABLE_CHANGE_PAGE = 'TABLE/CHANGE_PAGE'
export const TABLE_CHANGE_ORDER = 'TABLE/CHANGE_ORDER'
export const TABLE_SHOW_ALERT = 'TABLE/SHOW_ALERT'
export const TABLE_HIDE_ALERT = 'TABLE/HIDE_ALERT'
export const TABLE_FETCH_ANALYSIS_MAPS = 'TABLE/FETCH_ANALYSIS_MAPS'
export const TABLE_ADD_PLACEHOLDERS = 'TABLE/ADD_PLACEHOLDERS'

let URL_API = process.env.REACT_APP_URL_API

// Action Creators

export function targetFetchRow(plateifu) {
  return async (dispatch) => {
    const response = await fetch(`${URL_API}/targets/${plateifu}/`)
    const json = await response.json()
    dispatch({ type: TARGET_FETCH_ROW, payload: json })
  }
}

export function targetSetPointer(pointXY) {
  return { type: TARGET_SET_POINTER, payload: pointXY }
}

export function targetReduxMapShowLoader() {
  return { type: TARGET_REDUX_MAP_SHOW_LOADER }
}

export function targetReduxMapHideLoader() {
  return { type: TARGET_REDUX_MAP_HIDE_LOADER }
}

export function targetReduxSpectrumShowLoader() {
  return { type: TARGET_REDUX_SPECTRUM_SHOW_LOADER }
}

export function targetReduxSpectrumHideLoader() {
  return { type: TARGET_REDUX_SPECTRUM_HIDE_LOADER }
}

export function targetDAPSpectrumShowLoader() {
  return { type: TARGET_DAP_SPECTRUM_SHOW_LOADER }
}

export function targetDAPSpectrumHideLoader() {
  return { type: TARGET_DAP_SPECTRUM_HIDE_LOADER }
}

export function targetFetchReduxMap(plateifu) {
  return async (dispatch) => {
    try {
      dispatch(targetReduxMapShowLoader())
      const url = `${URL_API}/targets/${plateifu}/redux/map/`
      console.log(`Fetching redux map: ${url}`)
      const response = await fetch(url)
      const json = await response.json()
      dispatch({
        type: TARGET_FETCH_REDUX_MAP,
        payload: { plateifu: plateifu, data: json, status: 'ok', error: null },
      })
      console.log(`done: data =`, json)
      dispatch(targetReduxMapHideLoader())
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_FETCH_REDUX_MAP,
        payload: {
          plateifu: plateifu,
          data: {},
          status: 'error',
          error: error.message,
        },
      })
      dispatch(targetReduxMapHideLoader())
    }
  }
}

export function targetFetchReduxSpectrum(plateifu, pointXY) {
  return async (dispatch) => {
    try {
      dispatch(targetReduxSpectrumShowLoader())
      const response = await fetch(
        `${URL_API}/targets/${plateifu}/redux/spectrum/?x=${pointXY.x}&y=${pointXY.y}`
      )
      const json = await response.json()
      json.redux_spectrum.flux_uperr = json.redux_spectrum.flux.map(
        (e, i) => e + json.redux_spectrum.err[i]
      )
      json.redux_spectrum.flux_downerr = json.redux_spectrum.flux.map(
        (e, i) => e - json.redux_spectrum.err[i]
      )
      dispatch({
        type: TARGET_FETCH_REDUX_SPECTRUM,
        payload: { data: json, status: 'ok', error: null },
      })
      dispatch(targetReduxSpectrumHideLoader())
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_FETCH_REDUX_SPECTRUM,
        payload: {
          data: {},
          status: 'error',
          error: error.message,
        },
      })
      dispatch(targetReduxSpectrumHideLoader())
    }
  }
}

export function targetFetchDAPSpectrum(plateifu, pointXY) {
  return async (dispatch) => {
    try {
      dispatch(targetDAPSpectrumShowLoader())
      const response = await fetch(
        `${URL_API}/targets/${plateifu}/dap/spectrum/?x=${pointXY.x}&y=${pointXY.y}`
      )
      const json = await response.json()

      // json.dap_spectrum.fit = json.dap_spectrum.fit.map((e, i) =>
      //   json.dap_spectrum.fit[i] === 0 ? null : e
      // )
      // json.dap_spectrum.resid = json.dap_spectrum.flux.map(
      //   (e, i) => e - json.dap_spectrum.fit[i]
      // )
      // json.dap_spectrum.stars = json.dap_spectrum.fit.map(
      //   (e, i) => e - json.dap_spectrum.emis[i]
      // )
      json.dap_spectrum.resid = [...json.dap_spectrum.flux]
      json.dap_spectrum.stars = [...json.dap_spectrum.flux]
      json.dap_spectrum.errm = json.dap_spectrum.err.map((e) => -e)
      json.dap_spectrum.fit.forEach((e, i) => {
        json.dap_spectrum.stars[i] =
          json.dap_spectrum.fit[i] - json.dap_spectrum.emis[i]
        json.dap_spectrum.resid[i] =
          json.dap_spectrum.flux[i] - json.dap_spectrum.fit[i]
        if (e === 0) {
          json.dap_spectrum.fit[i] = null
          json.dap_spectrum.emis[i] = null
          json.dap_spectrum.stars[i] = null
          json.dap_spectrum.resid[i] = null
        }
      })
      dispatch({
        type: TARGET_FETCH_DAP_SPECTRUM,
        payload: { data: json, status: 'ok', error: null },
      })
      dispatch(targetDAPSpectrumHideLoader())
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_FETCH_DAP_SPECTRUM,
        payload: {
          data: {},
          status: 'error',
          error: error.message,
        },
      })
      dispatch(targetDAPSpectrumHideLoader())
    }
  }
}

export function targetFetchDapMapsList(plateifu) {
  return async (dispatch) => {
    try {
      const url = `${URL_API}/targets/${plateifu}/dap/maps/list/`
      const response = await fetch(url)
      const json = await response.json()
      dispatch({
        type: TARGET_FETCH_DAP_MAPS_LIST,
        payload: {
          plateifu: plateifu,
          data: json.maps_list,
          status: 'ok',
          error: null,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_FETCH_DAP_MAPS_LIST,
        payload: {
          plateifu: plateifu,
          data: {},
          status: 'error',
          error: error.message,
        },
      })
    }
  }
}

export function targetFetchDapBPTS(plateifu) {
  return async (dispatch) => {
    try {
      const url = `${URL_API}/targets/${plateifu}/dap/bpts/`
      const response = await fetch(url)
      const json = await response.json()
      dispatch({
        type: TARGET_FETCH_DAP_BPTS,
        payload: {
          plateifu: plateifu,
          data: json.dap_bpt,
          status: 'ok',
          error: null,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_FETCH_DAP_BPTS,
        payload: {
          plateifu: plateifu,
          data: {},
          status: 'error',
          error: error.message,
        },
      })
    }
  }
}

export function updateDapMapSelected(array) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({ type: TARGET_UPDATE_DAP_MAPS_SELECTED, payload: array })
    dispatch(tableFetchAnalysisMaps(state.target.row.plateifu))
  }
}

export function targetChangeBinSchema(binschema) {
  return { type: TARGT_CHANGE_DAP_BINSCHEMA, payload: { binschema: binschema } }
}

///////////////////////////////////////////////////////////////////////////////
// Table Actions

export function tableSetQueryString(query_string) {
  return { type: TABLE_SET_QUERY_STRING, payload: query_string }
}

export function tableFetchRows(query) {
  return async (dispatch) => {
    try {
      dispatch(tableSetQueryString(query))
      dispatch({
        type: TABLE_FETCH_ROWS,
        payload: { data: [], status: 'loading' },
      })
      const response = await fetch(`${URL_API}/targets/?q=${query}`)
      const json = await response.json()
      dispatch({
        type: TABLE_FETCH_ROWS,
        payload: { data: json, status: 'fetched' },
      })
      if (json.length === 0) {
        dispatch(tableShowAlert('No data for given query.', 'warning'))
      } else {
        const plateifus = json.map((row) => row.plateifu)
        dispatch(tableAddPlaceholders(plateifus))
      }
    } catch (error) {
      dispatch({
        type: TABLE_FETCH_ROWS,
        payload: { data: [], status: 'error' },
      })
      dispatch(
        tableShowAlert(
          'Oops... Something went wrong during loading. Check your query.',
          'error'
        )
      )
    }
  }
}

export function tableChangeView(view) {
  return { type: TABLE_CHANGE_VIEW, payload: view }
}

export function tableChangePageSize(pageSize) {
  return { type: TABLE_CHANGE_PAGESIZE, payload: pageSize }
}

export function tableChangePage(page) {
  return { type: TABLE_CHANGE_PAGE, payload: page }
}

export function tableChangeOrder(column, direction) {
  return { type: TABLE_CHANGE_ORDER, payload: { column, direction } }
}

export function tableShowAlert(text, severity) {
  return (dispatch) => {
    dispatch({
      type: TABLE_SHOW_ALERT,
      payload: { text, severity },
    })
  }
}

export function tableHideAlert() {
  return { type: TABLE_HIDE_ALERT }
}

export function tableAddPlaceholders(plateifus) {
  return { type: TABLE_ADD_PLACEHOLDERS, payload: plateifus }
}

export function tableFetchAnalysisMaps(plateifu) {
  return async (dispatch, getState) => {
    const state = getState()
    const mapsSelected = state.target.DAPMapsSelected.toString()

    // fetch also row data
    dispatch(targetFetchRow(plateifu))

    try {
      const url = `${URL_API}/targets/${plateifu}/dap/maps/?names=${mapsSelected}`
      console.log(`Fetching maps: ${url}`)
      const response = await fetch(url)
      const json = await response.json()
      dispatch({
        type: TABLE_FETCH_ANALYSIS_MAPS,
        payload: { plateifu: plateifu, data: json, status: 'ok', error: null },
      })
      console.log(`Fetched maps: ${plateifu}: `, json)
    } catch (error) {
      console.log(error)
      dispatch({
        type: TABLE_FETCH_ANALYSIS_MAPS,
        payload: {
          plateifu: plateifu,
          data: {},
          status: 'error',
          error: error.message,
        },
      })
    }
  }
}
