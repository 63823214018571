import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { targetFetchRow } from '../../../redux/actions.js'
import { Grid, Typography, Box, Link } from '@material-ui/core'

import { OutputSkeleton } from '../../Loaders.js'

const Param = ({ label, value, large }) => {
  return (
    <Box component="div" display="inline">
      {large ? (
        <Box fontSize={15}>{label}</Box>
      ) : (
        <Box fontSize={13}>{label}</Box>
      )}

      {large ? (
        <Typography variant="h5">{value}</Typography>
      ) : (
        <Typography>{value}</Typography>
      )}
    </Box>
  )
}

const TargetInfo = ({ plateifu }) => {
  const row = useSelector((state) => state.target.row)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(targetFetchRow(plateifu))
  }, [dispatch, plateifu])

  return (
    <Fragment>
      {!row ? (
        <OutputSkeleton />
      ) : (
        <Fragment>
          <Grid container direction="row" justify="flex-start" spacing={3}>
            <Grid item className="DragHandle" style={{ cursor: 'move' }}>
              <Param label="DAP" value="Parameters:" large />
            </Grid>
            <Grid item>
              <Param label="plateifu" value={row['plateifu']} large />
            </Grid>
            <Grid item>
              <Param label="mangaid" value={row['mangaid']} large />
            </Grid>

            <Grid item>
              <Box mt={3}>
                Go to:
                <Box component="span" mx={1}>
                  <Link
                    href={`https://dr16.sdss.org/marvin/galaxy/${plateifu}/`}
                    target="_blank"
                    rel="noopener"
                  >
                    Marvin
                  </Link>
                </Box>
                <Box component="span" mx={1}>
                  <Link
                    href={`http://rcsed-dev.sai.msu.ru/catalog/?f=${row['ifura']} ${row['ifudec']}`}
                    target="_blank"
                    rel="noopener"
                  >
                    RCSED
                  </Link>
                </Box>
                <Box component="span" mx={1}>
                  <Link
                    href={`http://ned.ipac.caltech.edu/cgi-bin/objsearch?search_type=Near+Position+Search&in_csys=Equatorial&in_equinox=J2000.0&lon=${row['ifura']}d&lat=${row['ifudec']}d&radius=0.2&hconst=73&omegam=0.27&omegav=0.73&corr_z=1&z_constraint=Unconstrained&z_value1=&z_value2=&z_unit=z&ot_include=ANY&nmp_op=ANY&out_csys=Equatorial&out_equinox=J2000.0&obj_sort=Distance+to+search+center&of=pre_text&zv_breaker=30000.0&list_limit=5&img_stamp=YES`}
                    target="_blank"
                    rel="noopener"
                  >
                    NED
                  </Link>
                </Box>
              </Box>
            </Grid>
            {Object.keys(row)
              .filter((key) => !['meta', 'plateifu', 'mangaid'].includes(key))
              .map((key) => {
                return (
                  <Grid item key={key}>
                    <Param label={key} value={row[key]} />
                  </Grid>
                )
              })}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  )
}

export default TargetInfo
