import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import MaterialTable, { MTableToolbar } from 'material-table'
// replaced original material-table package since it includes unused jspdf 300kb module
import MaterialTable, { MTableToolbar } from '@seromenho/material-table'

import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  makeStyles,
  TablePagination,
  Select,
  MenuItem,
} from '@material-ui/core'
import { SDSS } from '../visualiser/Cutouts'

import { TableRow, TableCell } from '@material-ui/core'
import {
  tableChangePageSize,
  tableChangePage,
  tableChangeView,
  tableChangeOrder,
} from '../../redux/actions.js'
import PanelObject from './PanelObject.js'

const TitleSub = (props) => (
  <div>
    {props.v}
    <sub>{props.s}</sub>
    {props.e && props.e}
  </div>
)

const columns = [
  {
    title: '',
    field: 'imageUrl',
    height: 20,
    render: (rowData) => (
      <SDSS
        ra={rowData.ifura}
        dec={rowData.ifudec}
        size={90}
        pixscale={0.3}
        imgsize={30}
        opt={''}
      />
      // <img
      //   // src={`https://data.sdss.org/sas/dr16/manga/spectro/redux/v2_4_3/${rowData.plate}/stack/images/${rowData.ifudsgn}_thumb.png`}
      //   src={`https://skyserver.sdss.org/dr16/SkyServerWS/ImgCutout/getjpeg?TaskName=Skyserver.Explore.Image&ra=${rowData.ifura}&dec=${rowData.ifudec}&scale=0.75&width=30&height=30`}
      //   style={{ width: 30 }}
      //   alt="thumbnail"
      // />
    ),
  },
  {
    title: 'plateifu',
    field: 'plateifu',
    render: (rowData) => (
      <div style={{ width: '90px' }}>
        <NavLink to={`visualiser/${rowData.plateifu}`}>
          <strong>{rowData.plateifu}</strong>
        </NavLink>
      </div>
    ),
  },
  { title: 'mangaid', field: 'mangaid', width: 100 },
  { title: 'plate', field: 'plate', width: 70 },
  { title: 'ifudsgn', field: 'ifudsgn', width: 90 },
  { title: 'RA', field: 'ifura', width: 90 },
  { title: 'DEC', field: 'ifudec', width: 90 },
  { title: 'z', field: 'z', width: 100 },
  {
    title: <TitleSub v="M" s="star" />,
    field: 'nsa_elpetro_mass',
    render: (rowData) =>
      rowData.nsa_elpetro_mass && rowData.nsa_elpetro_mass.toExponential(2),
  },
  {
    title: <TitleSub v="R" s="eff" />,
    field: 'nsa_elpetro_th50_r',
    width: 100,
  },
  { title: 'Phi', field: 'nsa_elpetro_phi', width: 100 },
  { title: 'PA', field: 'nsa_elpetro_ba', width: 100 },
  { title: <TitleSub v="n" s="Sersic" />, field: 'nsa_sersic_n', width: 100 },
  {
    title: <TitleSub v="M" s="abs" e="(FUV)" />,
    field: 'nsa_elpetro_absmag_f',
    width: 105,
  },
  {
    title: <TitleSub v="M" s="abs" e="(NUV)" />,
    field: 'nsa_elpetro_absmag_n',
    width: 105,
  },
  {
    title: <TitleSub v="M" s="abs" e="(u)" />,
    field: 'nsa_elpetro_absmag_u',
    width: 90,
  },
  {
    title: <TitleSub v="M" s="abs" e="(g)" />,
    field: 'nsa_elpetro_absmag_g',
    width: 90,
  },
  {
    title: <TitleSub v="M" s="abs" e="(r)" />,
    field: 'nsa_elpetro_absmag_r',
    width: 90,
  },
  {
    title: <TitleSub v="M" s="abs" e="(i)" />,
    field: 'nsa_elpetro_absmag_i',
    width: 90,
  },
  {
    title: <TitleSub v="M" s="abs" e="(z)" />,
    field: 'nsa_elpetro_absmag_z',
    width: 90,
  },
  { title: <TitleSub v="SN" s="blue" />, field: 'bluesn2', width: 90 },
  { title: <TitleSub v="SN" s="red" />, field: 'redsn2', width: 90 },
  { title: 'seemed', field: 'seemed', width: 90 },
  { title: <TitleSub v="N" s="exp" />, field: 'nexp', width: 70 },
  { title: 'mngtarg1', field: 'mngtarg1', width: 100 },
  { title: 'mngtarg3', field: 'mngtarg3', width: 100 },
  { title: 'ifudesignsize', field: 'ifudesignsize', width: 100 },
]

const ControlTablePanel = (props) => {
  const dispatch = useDispatch()
  const view = useSelector((state) => state.table.view)

  return (
    <FormControl className="px-2">
      <RadioGroup
        name="typeView"
        value={view}
        onChange={(event) => {
          if (event.target.value === 'table') {
            dispatch(tableChangePageSize(20))
          } else {
            dispatch(tableChangePageSize(5))
          }
          dispatch(tableChangeView(event.target.value))
        }}
        row
      >
        <FormControlLabel value="table" control={<Radio />} label="Table" />
        <FormControlLabel value="panels" control={<Radio />} label="Panels" />
      </RadioGroup>
      {props.children}
    </FormControl>
  )
}

const useStylesToolbar = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
}))

const useStylesPagination = makeStyles((theme) => ({
  root: {
    float: 'left',
    width: '30vw',
  },
  select: {
    position: 'relative',
    bottom: '-1px',
    fontSize: '14px',
  },
}))

export function TableBoard(props) {
  const classesToolbar = useStylesToolbar()
  const classesPagination = useStylesPagination()
  const data = useSelector((state) => state.table.rows)
  const pageSize = useSelector((state) => state.table.pageSize)
  const page = useSelector((state) => state.table.page)
  const npages = useSelector((state) => state.table.npages)
  const orderColumn = useSelector((state) => state.table.orderColumn)
  const orderDirection = useSelector((state) => state.table.orderDirection)
  const view = useSelector((state) => state.table.view)
  const dispatch = useDispatch()
  const columnsUpdated = [...columns]

  if (orderColumn !== -1) {
    columnsUpdated[orderColumn]['defaultSort'] = orderDirection
  }

  const pageSizeOptions =
    view === 'table'
      ? [3, 5, 7, 10, 15, 20, 30, 50, 100, 300, 500]
      : [3, 5, 7, 10, 15]

  return (
    <Fragment>
      <MaterialTable
        key={`${pageSize}${page}`}
        title={null}
        columns={columnsUpdated}
        data={data}
        options={{
          padding: 'dense',
          initialPage: page - 1,
          pageSizeOptions: pageSizeOptions,
          pageSize: pageSize,
          draggable: false,
          rowStyle: {
            fontSize: 14,
          },
        }}
        onChangePage={(n) => dispatch(tableChangePage(n + 1))}
        onChangeRowsPerPage={(p) => dispatch(tableChangePageSize(p))}
        onOrderChange={(column, direction) => {
          dispatch(tableChangeOrder(column, direction))
        }}
        components={{
          Toolbar: (props) => (
            <Box display="flex" justifyContent="space-between">
              <ControlTablePanel {...props} />
              <MTableToolbar {...props} classes={classesToolbar} />
            </Box>
          ),
          Pagination: (props) => {
            return (
              <Fragment>
                <TableCell>
                  <Select
                    value={page}
                    onChange={(event) => {
                      dispatch(tableChangePage(event.target.value))
                    }}
                    disableUnderline
                    classes={{ select: classesPagination.select }}
                  >
                    {Array(npages)
                      .fill()
                      .map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          Page {i + 1}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
                <TablePagination
                  {...props}
                  // classes={{ root: classesPagination.root }}
                  style={{ float: 'inherit', width: 'unset' }}
                />
              </Fragment>
            )
          },
          ...(view === 'panels' && {
            Row: (props) => (
              <TableRow>
                <TableCell align="left" colSpan={props.columns.length}>
                  <PanelObject row={props.data} />
                </TableCell>
              </TableRow>
            ),
          }),
        }}
      />
    </Fragment>
  )
}
