import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tableFetchAnalysisMaps, targetFetchRow } from '../../redux/actions.js'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Grid, Box } from '@material-ui/core'

import { OutputSkeleton } from '../Loaders.js'
import Map2d from './Map2d.js'
import { SDSS, LegacySurvey, LegacySurveyVLASS } from './Cutouts'

export const ErrorAlert = () => (
  <Box
    width={400}
    height={250}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Something went wrong during data loading...
    </Alert>
  </Box>
)

export const MapsBasicContainer = (props) => {
  const dispatch = useDispatch()
  // const mapLoading = useSelector((state) => state.target.reduxMapLoading)
  const mapsData = useSelector(
    (state) => state.table.analysisMaps[props.plateifu]
  )
  const row = useSelector((state) => state.target.row)
  const pointXY = useSelector((state) => state.target.pointerXY)
  const mapsSelected = useSelector((state) => state.target.DAPMapsSelected)

  console.log('mapsData: ', mapsData)
  // console.log('mapsData: ', mapsData.data.maps)
  console.log('mapsSelected:', mapsSelected)

  // if mapsData has not been loaded yet, do it now
  if (typeof mapsData === 'undefined' || row === null) {
    dispatch(tableFetchAnalysisMaps(props.plateifu))
    return <OutputSkeleton />
  }

  if (Object.entries(mapsData).length === 0 || row === null) {
    dispatch(tableFetchAnalysisMaps(props.plateifu))
    return <OutputSkeleton />
  } else if (mapsData.status === 'error') {
    return <ErrorAlert />
  }

  let range
  if (mapsData.hasOwnProperty('data')) {
    range = [
      mapsData.data.meta.xrange[0],
      mapsData.data.meta.xrange[1],
      mapsData.data.meta.yrange[0],
      mapsData.data.meta.yrange[1],
    ]
  } else {
    range = [-20, 20, -20, 20]
  }

  return (
    <Fragment>
      <Grid container direction="row" justify="flex-start" spacing={3}>
        <Grid item>
          <SDSS
            ra={row.ifura}
            dec={row.ifudec}
            style={{ display: 'inline-block' }}
          />
        </Grid>
        <Grid item>
          <LegacySurvey
            ra={row.ifura}
            dec={row.ifudec}
            style={{ display: 'inline-block' }}
          />
        </Grid>
        <Grid item>
          <LegacySurveyVLASS
            ra={row.ifura}
            dec={row.ifudec}
            style={{ display: 'inline-block' }}
          />
        </Grid>
        {Object.keys(mapsData.data.maps).map((key) => {
          const data = [
            {
              z: mapsData.data.maps[key].data,
              x: mapsData.data.meta.xcoord,
              y: mapsData.data.meta.ycoord,
              zmin: mapsData.data.maps[key].zrange[0],
              zmax: mapsData.data.maps[key].zrange[1],
            },
          ]
          return (
            <Grid item key={`${props.plateifu}-grid-${key}`}>
              <Map2d
                key={`${props.plateifu}-${key}`}
                title={key}
                range={range}
                data={data}
                pointXY={pointXY}
              />
            </Grid>
          )
        })}
      </Grid>
    </Fragment>
  )
}
