import React, { Fragment } from 'react'

import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Badge,
  Divider,
  Box,
  Container,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import ListIcon from '@material-ui/icons/List'
import ButtonLink from './ButtonLink.js'

const useStyles = makeStyles((theme) => ({
  bkg: {
    backgroundImage:
      'linear-gradient(159deg, hsla(283.02, 0%, 35.98%, 1) 5%, hsla(283.02, 0%, 35.98%, 0) 73%), linear-gradient(245deg, hsla(64.5, 0%, 0%, 1) 100%, hsla(64.5, 0%, 0%, 0) 70%);',
    height: '40vh',
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'hidden',
  },
  panels: {
    width: '100vw',
    top: '-30px',
    position: 'relative',
  },
  card: {
    width: 380,
  },
  cardContent: {
    height: 190,
  },
  badge: {
    padding: '0 10px',
  },
  ribbon: {
    width: '300px',
    padding: '5px 0',
    background: theme.palette.secondary.main,
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    top: '70px',
    right: '-70px',
    transform: 'rotate(45deg)',
  },
}))

const CardName = (props) => {
  const classes = useStyles()
  return (
    <Fragment>
      {props.soon ? (
        <Badge
          badgeContent={'Coming soon'}
          color="secondary"
          className={classes.badge}
        >
          <Typography gutterBottom variant="h4" component="h2">
            {props.icon} {props.name}
          </Typography>
        </Badge>
      ) : (
        <Typography gutterBottom variant="h4" component="h2">
          {props.icon} {props.name}
        </Typography>
      )}
    </Fragment>
  )
}

const CardPanel = (props) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Card className={classes.card} elevation={10}>
        <CardContent className={classes.cardContent}>
          <CardName {...props} />
          <Divider className="mb-3" />
          <Typography variant="body1" color="textSecondary" component="p">
            {props.descripion}
          </Typography>
        </CardContent>
        <CardActions>
          <Fragment>{props.children}</Fragment>
        </CardActions>
      </Card>
    </Grid>
  )
}

function Home() {
  const classes = useStyles()
  return (
    <Box width="100vw">
      <Grid
        container
        align="center"
        justify="center"
        direction="column"
        className={classes.bkg}
      >
        <Box className={classes.ribbon}>
          <Typography variant="subtitle1">Under Development</Typography>
        </Box>
        <Box>
          <Typography
            variant="h2"
            components="h2"
            className="white-text text-center"
          >
            MaNGA Explorer
          </Typography>
          <Typography
            variant="h4"
            components="h4"
            className="white-text text-center"
          >
            Search and visualise
          </Typography>
          <Typography
            variant="h5"
            components="h5"
            className="white-text text-center"
          >
            DR17 (MPL-11)
          </Typography>
        </Box>
      </Grid>

      <Grid container justify="center" spacing={10} className={classes.panels}>
        <CardPanel
          name="Search"
          icon={<SearchIcon fontSize="large" />}
          descripion="Search and filter MaNGA targets, use Cone search and simple Google-like syntax."
        >
          <ButtonLink
            to="/search"
            variant="contained"
            color="primary"
            size="small"
          >
            Go Search
          </ButtonLink>
          <ButtonLink to="/about" variant="outlined" size="small">
            Learn More
          </ButtonLink>
        </CardPanel>

        <CardPanel
          name="Visualisation"
          icon={<ShowChartIcon fontSize="large" />}
          descripion="Interactively visualise Data Analysis Products (DAP) maps and spectra for individual targets or samples of targets."
        >
          <ButtonLink
            to="/visualiser"
            variant="contained"
            color="primary"
            size="small"
          >
            Go visualise
          </ButtonLink>
          <ButtonLink to="/about" variant="outlined" size="small">
            Learn More
          </ButtonLink>
        </CardPanel>

        <CardPanel
          name="Collections"
          soon
          icon={<ListIcon fontSize="large" />}
          descripion="Compile collections of interesting targets and share them to
                  collaborators."
        >
          <ButtonLink to="/about" variant="outlined" size="small">
            Learn More
          </ButtonLink>
        </CardPanel>
      </Grid>

      <Container className="mt-4">
        <p>
          <Typography gutterBottom>
            The data used in this visualiser are produced in the frame of{' '}
            <a
              href="https://www.sdss.org/dr17/manga/"
              target="_blank"
              rel="noopener noreferrer"
            >
              the MaNGA survey
            </a>
            , which is part of
            <a
              href="https://www.sdss.org/dr17/"
              target="_blank"
              rel="noopener noreferrer"
            >
              the SDSS-IV project
            </a>
            .
          </Typography>
        </p>
        <p>
          <Typography gutterBottom>
            The service visualizes spectra of galaxies and maps of properties
            obtained with the{' '}
            <a
              href="https://www.sdss.org/dr17/manga/manga-analysis-pipeline/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Analysis Pipeline
            </a>{' '}
            and released in DR17. Details of the survey data products (along
            with the necessary links for those who want to use the data) can be
            found{' '}
            <a
              href="https://www.sdss.org/dr17/manga/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Typography>
        </p>
      </Container>
    </Box>
  )
}

export default Home
